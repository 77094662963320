import React, { Component } from 'react';
import Products from './private/Products';
import firebase from './firebase';
export default class Admin extends Component {
    constructor(props){
        super(props);
        this.state = {
            name: "", 
            productID:"",
            categoryID:"", 
            categoryName:"", 
            code:"", 
            id:"", 
            profitPerUnit:"", 
            umID:"",
            umName:"",
            center:"",
            idProduct:"",
            productName:"",
            reciclerCenter:[],
            categories:[],
            products:[],
            cboProducts:[],
            measureUnits:[],
            orders:[]
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEditar = this.handleEditar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSetChange = this.handleSetChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeProduct = this.handleChangeProduct.bind(this);

    }
    
    makeId() { 
        var text = ""; 
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; 
        for (var i = 0; i < 6; i++) text += possible.charAt(Math.floor(Math.random() * possible.length)); 
        return text; } 

    handleSubmit = (e)=> {
        
        let productos = [];
        var productosRef = firebase.firestore().collection('ReciclerCenters');
        var idCentro = this.state.center;
        let prod = [];

        if(this.state.categoryID===""){ alert("Debe indicar una categoria"); return false;}
        if(this.state.categoryName===""){ alert("Debe indicar una categoria"); return;}
        if(this.state.name===""){ alert("Debe indicar un nombre"); return;}
        if(this.state.umID===""){ alert("Debe indicar la unidad de medida"); return;}
        if(this.state.umName===""){ alert("Debe indicar la unidad de medida"); return;}
        if(this.state.profitPerUnit===""){ alert("Debe indicar el valor del material"); return;}

        productosRef.doc(idCentro).collection("products").add({
            categoryID: this.state.categoryID,
            categoryName: this.state.categoryName,
            code: this.makeId(),
            name: this.state.name,
            profitPerUnit: this.state.profitPerUnit,
            umID: this.state.umID,
            umName: this.state.umName
        }).then((ref)=>{
            console.log(ref);
            productosRef.doc(idCentro).collection('products').doc(ref.id).update({id: ref.id});
        }).catch(function(error){
            console.log("Error getting documents");
        });


      }

      handleEditar = (e)=> {
        
        let productos = [];
        var productosRef = firebase.firestore().collection('ReciclerCenters');
        var idCentro = this.state.center;
        var idProduct = this.state.idProduct;
        let prod = [];

        if(this.state.categoryID===""){ alert("Debe indicar una categoria"); return false;}
        if(this.state.categoryName===""){ alert("Debe indicar una categoria"); return;}
        if(this.state.name===""){ alert("Debe indicar un nombre"); return;}
        if(this.state.umID===""){ alert("Debe indicar la unidad de medida"); return;}
        if(this.state.umName===""){ alert("Debe indicar la unidad de medida"); return;}
        if(this.state.profitPerUnit===""){ alert("Debe indicar el valor del material"); return;}

        productosRef.doc(idCentro).collection("products").doc(idProduct).set({
            categoryID: this.state.categoryID,
            categoryName: this.state.categoryName,
            name: this.state.name,
            profitPerUnit: this.state.profitPerUnit,
            umID: this.state.umID,
            umName: this.state.umName
        }, {merge: true}).then((ref)=>{
            console.log(ref);
            productosRef.doc(idCentro).collection('products').doc(ref.id).update({id: ref.id});
        }).catch(function(error){
            console.log("Error getting documents");
        });

        //array de productos
        productosRef.get().then((snapshot)=>{
            snapshot.forEach((doc)=>{
                productosRef.doc(doc.id).collection("products").get().then((snapshot)=>{
                     snapshot.forEach((ref,i)=>{
                         //productos.push(ref.data());
                         productos = ref.data();
                         productos.center = doc.data().name;
                         productos.centerId = doc.id;
                         prod.push(productos);
                         //productos.push(doc.data().name);
                        // console.log(ref.data());
                          
                     }) 
                     this.setState({ products:prod })     
                     
                })
            })
            
         }).catch((error)=>{
             console.log("Error getting products");
         })
      }

      handleChange = input => e =>{
        this.setState({
            [input]: e.target.value
        });
    }

    handleSetChange = (params)=>{
        
        console.log(params);
        this.setState({
            categoryName: params.categoryName,
            umName: params.umName,
            name: params.name,
            idProduct: params.idProduct,
            productID: params.productID,
            categoryID: params.categoryID,
            umID: params.umID,
            center: params.center,
            profitPerUnit: params.profitPerUnit
        });
    }

    handleChangeSelect = input => e =>{
        
        console.log(e._targetInst.memoizedProps.children[0][0]);
        console.log(e.target.name);
        console.log(input);
        console.log(e.target.value);
        this.setState({
            [input]: e.target.value,
            [e.target.name]: e._targetInst.memoizedProps.children[0][0]
        });

        console.log(this.state)
    }

    handleChangeProduct = input => e =>{
        
        console.log(e._targetInst.memoizedProps.children[0][0]);
        console.log(e.target.name);
        console.log(input);
        console.log(e.target.value);
        this.setState({
            [input]: e.target.value,
            [e.target.name]: e._targetInst.memoizedProps.children[0][0],
            name: e._targetInst.memoizedProps.children[0][0]
        });

        console.log(this.state)
    }

    componentDidMount(){
        const centros=[];
        const unidades=[];
        const categorias=[];
        const products=[];
        const centrosRef = firebase.firestore().collection("ReciclerCenters");
        const categoryRef = firebase.firestore().collection("ProductCategories");
        const measureUnitsRef = firebase.firestore().collection("MeasureUnits");
        const productosRef = firebase.firestore().collection("ReciclerCenters");
        const cboProductosRef = firebase.firestore().collection("Products").orderBy('name');
        const ordenesRef = firebase.firestore().collection('Orders');
        const ordenes = [];
        let productos = [];
        let prod = [];
    
        //array de productos
        productosRef.get().then((snapshot)=>{
            snapshot.forEach((doc)=>{
                productosRef.doc(doc.id).collection("products").get().then((snapshot)=>{
                     snapshot.forEach((ref,i)=>{
                         //productos.push(ref.data());
                         productos = ref.data();
                         productos.center = doc.data().name;
                         productos.centerId = doc.id;
                         prod.push(productos);
                         //productos.push(doc.data().name);
                        // console.log(ref.data());
                          
                     }) 
                     this.setState({ products:prod })     
                     
                })
            })
            
         }).catch((error)=>{
             console.log("Error getting products");
         })

        ordenesRef.get().then((snapshot)=>{
            snapshot.forEach((doc)=>{
                ordenes.push(doc.data());
            });
            this.setState({
                orders:ordenes
            })
        }).catch((error)=>{
            console.log("Ocurrio un error listando las ordenes");
        });

        //array de cbo de productos
        cboProductosRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
                products.push(doc.data());
        });
        this.setState({ cboProducts:products })
        }).catch((error)=>{
        console.log("Error getting documents");     
        });
//array de centros
        centrosRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
               // console.log(doc.data());
                centros.push(doc.data());
          });
          this.setState({ reciclerCenter:centros })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });
//array de categorias
        categoryRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
               // console.log(doc.data());
                categorias.push(doc.data());
          });
          this.setState({ categories:categorias })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });

//array de Unidades de medidas
        measureUnitsRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
               // console.log(doc.data());
                unidades.push(doc.data());
          });
          this.setState({ measureUnits:unidades })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });        
    }

    actualizar = ()=>{
        let productos = [];
        let prod = [];
        const productosRef = firebase.firestore().collection("ReciclerCenters");
        productosRef.get().then((snapshot)=>{
            snapshot.forEach((doc)=>{
                productosRef.doc(doc.id).collection("products").get().then((snapshot)=>{
                     snapshot.forEach((ref,i)=>{
                         //productos.push(ref.data());
                         productos = ref.data();
                         productos.center = doc.data().name;
                         productos.centerId = doc.id;
                         prod.push(productos);
                         //productos.push(doc.data().name);
                        // console.log(ref.data());
                          
                     }) 
                     this.setState({ products:prod })     
                     
                })
            })
            
         }).catch((error)=>{
             console.log("Error getting products");
         });
    }

    handleDelete = (selected)=>{
        const refOrders = firebase.firestore().collection("ReciclerCenter");
        //refOrders.doc(selected[0]).update({ status: 'Contactado' }).then((res) => {
         // console.log("se actualizó el registro");
        //}).catch(err => {
         // console.log("no se pudo actualizar el registro");
        //});
    
         console.log(selected[0]);
      }

      handleEdit = (selected)=>{
         console.log(selected[0]);
        
        }
    
    handleStatus = (selected)=>{
        const refOrders = firebase.firestore().collection("Orders");
        refOrders.doc(selected[0]).update({ status: 'Contactado' }).then((res) => {
          console.log("se actualizó el registro !!!");
    
        }).catch(err => {
          console.log("no se pudo actualizar el registro");
        });
    
        // console.log(selected[0]);
      }

    render(){
        const { name, categoryID, categoryName, code, id, profitPerUnit, umID,umName,reciclerCenter,center,categories,measureUnits,products,cboProducts,productID,productName } = this.state;
        const values = { name, categoryID, categoryName, code, id, profitPerUnit, umID,umName,reciclerCenter,center,categories,measureUnits,products,cboProducts,productID,productName }
       // console.log(this.state.products);
        return(
            <React.Fragment>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 text-left mb-5">
                            <span className="title">Productos</span>
                        </div>
                        <div className="col-12">
                        <Products  handleEditar={(e) => this.handleEditar(e)} handleSubmit={(e) => this.handleSubmit(e)} handleChange={this.handleChange} handleSetChange={this.handleSetChange} handleChangeSelect={this.handleChangeSelect} handleChangeProduct={this.handleChangeProduct} values={values} productos={this.state.products} handleEdit={this.handleEdit} handleDelete={this.handleDelete} handleStatus={this.handleStatus} actualizar={this.actualizar}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}