import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Hidden from '@material-ui/core/Hidden';
import banner from './banner.png';

export default class Banner extends Component{
    constructor(props){
        super(props)

        this.state = {}
    }

    render(){
        return(
            <div className="container-fluid">
                <div className="banner">
                    <img src={banner} width="1800px" className="img-fluid" alt="Responsive image"></img>
                    <Hidden smDown>
                    <div className="texto-banner" style={{ backgroundColor: "#1BE9B6" }}>
                        <p style={{fontSize:"2vw"}}>La forma más facil de reciclar,<br /> directamente a la puerta<br />de tu casa.</p>    
                        <Link to="/recicla" style={{ color: "#ffffff", fontSize: "22px", fontWeight: "bold", textDecoration: "none" }}>Recicla &nbsp;&nbsp;></Link>
                    </div>
                    </Hidden>
                    <Hidden smUp>
                    <div className="texto-banner" style={{ backgroundColor: "#1BE9B6", height:"130px", padding:"3%", top:"15%",left:"22%" }}>
                        <p style={{fontSize:"5vw", marginBottom: "0rem"}}>La forma más facil de reciclar,<br /> directamente a la puerta<br />de tu casa.</p>    
                        <Link to="/recicla" style={{ color: "#ffffff", fontSize: "22px", fontWeight: "bold", textDecoration: "none" }}>Recicla &nbsp;&nbsp;></Link>
                    </div>
                    </Hidden>
                </div>
                <a name="materiales"></a>
            </div>
        )
    }
}