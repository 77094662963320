import React, { Component } from 'react';



export default class Logout extends Component{
    constructor(props){
        super(props);

        this.state={
            isAuth: false
        }
    }

    Logout = (props)=>{
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.clear();
        this.props.history.push('/');
    }

    render(){
        return(
            <Logout />
        )
    }
}