import React, { Component } from 'react';
import CentrosForm from './private/CentrosForm';
import firebase from './firebase';


export default class Admin extends Component {
    constructor(props){
        super(props);
    
        this.state = { 
            centers: [],
            nombre:"",
            direccion: "",
            latitud: "",
            longitud: "",
            idCenter:""
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEditar = this.handleEditar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSetChange = this.handleSetChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeProduct = this.handleChangeProduct.bind(this);

    }
    

    componentDidMount(){
        const centrosRef = firebase.firestore().collection('ReciclerCenters');
        let centros = [];
    
        centrosRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
                centros.push(doc.data());
          });
          this.setState({ centers:centros })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });
    }

    handleSubmit = (e)=> {

        let centers = [];
        var centersRef = firebase.firestore().collection('ReciclerCenters');

        if(this.state.nombre===""){ alert("Debe indicar un nombre"); return false;}
        if(this.state.direccion===""){ alert("Debe indicar una direccion"); return false;}
        if(this.state.latitud===""){ alert("Debe indicar la latitud"); return false;}
        if(this.state.longitud===""){ alert("Debe indicar la longitud"); return false;}        

        console.log(this.state.nombre+" | "+this.state.direccion+" | "+this.state.latitud+" | "+this.state.longitud);
        let data = {
            name: this.state.nombre,
            address: this.state.direccion,
            location: new firebase.firestore.GeoPoint(parseFloat(this.state.latitud), parseFloat(this.state.longitud))
        }
        centersRef.add(data).then(ref => {
            centersRef.doc(ref.id).update({id: ref.id}).then(()=>{
            this.setState({nombre:"",direccion:"",latitud:"",longitud:""});
            centersRef.get().then((snapshot)=>{
                snapshot.forEach(function(doc) {
                        centers.push(doc.data());
                  });
                  this.setState({ centers:centers })
                  
              }).catch(function(error){
                  console.log("Error getting documents");
              })
            });
        });
      }

      handleEditar = (e)=> {
        
        let centers = [];
        var centrosRef = firebase.firestore().collection('ReciclerCenters');
        let cen = [];

        if(this.state.nombre===""){ alert("Debe indicar un nombre"); return false;}
        if(this.state.direccion===""){ alert("Debe indicar una direccion"); return false;}
        if(this.state.latitud===""){ alert("Debe indicar la latitud"); return false;}
        if(this.state.longitud===""){ alert("Debe indicar la longitud"); return false;}        

        console.log(this.state.idCenter);
        centrosRef.doc(this.state.idCenter).set({
            name: this.state.nombre,
            address: this.state.direccion,
            location: new firebase.firestore.GeoPoint(parseFloat(this.state.latitud), parseFloat(this.state.longitud))
        }, {merge: true}).then((ref)=>{
            console.log(ref);
        }).catch(function(error){
            console.log("Error getting documents");
        });

        //array de centros
        centrosRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
                    centers.push(doc.data());
              });
              this.setState({ centers:centers,
                nombre:"",
                direccion:"",
                longitud:"",
                latitud:"",
                idCenter:""
    })
              
          }).catch(function(error){
              console.log("Error getting documents");
          })
      }

      handleChange = input => e =>{
        this.setState({
            [input]: e.target.value
        });
    }

    handleSetChange = (params)=>{
        
        console.log(params);
        this.setState({
            idCenter: params.idCenter,
            nombre: params.name,
            direccion: params.address,
            latitud: params.latitud,
            longitud: params.longitud
        });
    }

    handleChangeSelect = input => e =>{
        
        console.log(e._targetInst.memoizedProps.children[0][0]);
        console.log(e.target.name);
        console.log(input);
        console.log(e.target.value);
        this.setState({
            [input]: e.target.value,
            [e.target.name]: e._targetInst.memoizedProps.children[0][0]
        });

        console.log(this.state)
    }

    handleChangeProduct = input => e =>{
        
        console.log(e._targetInst.memoizedProps.children[0][0]);
        console.log(e.target.name);
        console.log(input);
        console.log(e.target.value);
        this.setState({
            [input]: e.target.value,
            [e.target.name]: e._targetInst.memoizedProps.children[0][0],
            name: e._targetInst.memoizedProps.children[0][0]
        });

        console.log(this.state)
    }

    actualizar = ()=>{
        const centrosRef = firebase.firestore().collection('ReciclerCenters');
        let centros = [];

        centrosRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
                centros.push(doc.data());
          });
          this.setState({ centers:centros })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });
    }

    handleDelete = (selected)=>{
        const refCentros = firebase.firestore().collection("ReciclerCenters");
        refCentros.doc(selected[0]).update({ status: 'Contactado' },{merge: true}).then((res) => {
          console.log("se actualizó el registro");
        }).catch(err => {
          console.log("no se pudo actualizar el registro");
        });
    
        // console.log(selected[0]);
      }
    
    handleStatus = (selected)=>{
        const refCentros = firebase.firestore().collection("ReciclerCenters");
        refCentros.doc(selected[0]).update({ status: false },{merge: true}).then((res) => {
          console.log("se actualizó el registro !!!");
    
        }).catch(err => {
          console.log("no se pudo actualizar el registro");
        });
    
        // console.log(selected[0]);
      }

    render(){
        const { nombre, direccion, latitud, longitud, name, categoryID, categoryName, code, id, profitPerUnit, umID,umName,reciclerCenter,center,categories,measureUnits,products,cboProducts,productID,productName } = this.state;
        const values = { nombre, direccion, latitud, longitud }

        return(
            <React.Fragment>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 text-left mb-5">
                            <span className="title">Centros</span>
                        </div>
                        <div className="col-12">
                        <CentrosForm centers={this.state.centers} handleDelete={this.handleDelete} handleStatus={this.handleStatus} actualizar={this.actualizar} handleEditar={(e) => this.handleEditar(e)} handleSubmit={(e) => this.handleSubmit(e)} handleChange={this.handleChange} handleSetChange={this.handleSetChange} handleChangeSelect={this.handleChangeSelect} handleChangeProduct={this.handleChangeProduct} values={values} productos={this.state.products} handleEdit={this.handleEdit} handleDelete={this.handleDelete} handleStatus={this.handleStatus} actualizar={this.actualizar}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}