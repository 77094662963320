import React, { Component } from 'react';
import Orders from './private/Orders';
import firebase from './firebase';


export default class Admin extends Component {
    constructor(props){
        super(props);
    
        this.state = { orders: []}

    }
    

    componentDidMount(){
        const ordenesRef = firebase.firestore().collection('Orders');
        const detailsRef = firebase.firestore().collection('OrderDetails');
        let ordenes = [];
        let ord = [];
    
        ordenesRef.get().then((snapshot)=>{
            snapshot.forEach((doc)=>{
                detailsRef.where('orderID',"==",doc.id).get().then(snapshot => {
                    snapshot.forEach(docu => {
                      ordenes = doc.data();
                      ordenes.detalle = docu.data();
                      ord.push(ordenes);  
                    });
                    this.setState({
                        orders:ord
                    })
                  })
                  .catch(err => {
                    console.log('Error getting documents', err);
                  });
            });
        }).catch((error)=>{
            console.log("Ocurrio un error listando las ordenes");
        });
    }

    actualizar = ()=>{
        const ordenesRef = firebase.firestore().collection('Orders');
        const detailsRef = firebase.firestore().collection('OrderDetails');
        let ordenes = [];
        let ord = [];
    
       /* ordenesRef.get().then((snapshot)=>{
            snapshot.forEach((doc)=>{
                ordenes.push(doc.data());
            });
            this.setState({
                orders:ordenes
            })
        }).catch((error)=>{
            console.log("Ocurrio un error listando las ordenes");
        });*/
        ordenesRef.get().then((snapshot)=>{
            snapshot.forEach((doc)=>{
                detailsRef.where('orderID',"==",doc.id).get().then(snapshot => {
                    snapshot.forEach(docu => {
                      ordenes = doc.data();
                      ordenes.detalle = docu.data();
                      ord.push(ordenes);  
                    });
                    this.setState({
                        orders:ord
                    })
                  })
                  .catch(err => {
                    console.log('Error getting documents', err);
                  });
            });
        }).catch((error)=>{
            console.log("Ocurrio un error listando las ordenes");
        });
    }

    handleDelete = (selected)=>{
        const refOrders = firebase.firestore().collection("Orders");
        refOrders.doc(selected[0]).update({ status: 'Contactado' }).then((res) => {
          console.log("se actualizó el registro");
        }).catch(err => {
          console.log("no se pudo actualizar el registro");
        });
    
        // console.log(selected[0]);
      }
    
    handleStatus = (selected)=>{
        const refOrders = firebase.firestore().collection("Orders");
        refOrders.doc(selected[0]).update({ status: 'Contactado' }).then((res) => {
          console.log("se actualizó el registro !!!");
    
        }).catch(err => {
          console.log("no se pudo actualizar el registro");
        });
    
        // console.log(selected[0]);
      }

    render(){
        return(
            <React.Fragment>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 text-left mb-5">
                            <span className="title">Solicitudes</span>
                        </div>
                        <div className="col-12">
                        <Orders orders={this.state.orders} handleDelete={this.handleDelete} handleStatus={this.handleStatus} actualizar={this.actualizar}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}