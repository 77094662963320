import React, { Component } from 'react';
import CategoryForm from './private/CategoryForm';
import firebase from './firebase';


export default class Categoria extends Component {
    constructor(props){
        super(props);
    
        this.state = { 
            nombre:"",
            descripcion: "",
            idCategory: "",
            categories:[]
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEditar = this.handleEditar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSetChange = this.handleSetChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);

    }
    

    componentDidMount(){
        const categoryRef = firebase.firestore().collection('ProductCategories');
        let categorias = [];
    
        categoryRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
                categorias.push(doc.data());
          });
          this.setState({ categories:categorias })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });
    }

    handleSubmit = (e)=> {

        let categorias = [];
        var categoryRef = firebase.firestore().collection('ProductCategories');

        if(this.state.nombre===""){ alert("Debe indicar un nombre"); return false;}
        if(this.state.descripcion===""){ alert("Debe indicar una descripcion"); return false;}

        console.log(this.state.nombre+" | "+this.state.descripcion);
        let data = {
            name: this.state.nombre,
            description: this.state.descripcion
        }

        categoryRef.add(data).then(ref => {
            categoryRef.doc(ref.id).update({id: ref.id}).then(()=>{
            this.setState({nombre:"",descripcion:""});
            categoryRef.get().then((snapshot)=>{
                snapshot.forEach(function(doc) {
                        categorias.push(doc.data());
                  });
                  this.setState({ categories:categorias })
                  
              }).catch(function(error){
                  console.log("Error getting documents");
              })
            });
        });
      }

      handleEditar = (e)=> {
        
        let categorias = [];
        var categoryRef = firebase.firestore().collection('ProductCategories');
        let cen = [];

        if(this.state.nombre===""){ alert("Debe indicar un nombre"); return false;}
        if(this.state.descripcion===""){ alert("Debe indicar una descripcion"); return false;}

        console.log(this.state.idCategory);
        categoryRef.doc(this.state.idCategory).set({
            name: this.state.nombre,
            description: this.state.descripcion
        }, {merge: true}).then((ref)=>{
            console.log(ref);
        }).catch(function(error){
            console.log("Error getting documents");
        });

        //array de categorias
        categoryRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
                    categorias.push(doc.data());
              });
              this.setState({ categories:categorias,
                nombre:"",
                descripcion:"",
        })
              
          }).catch(function(error){
              console.log("Error getting documents");
          })
      }

      handleChange = input => e =>{
        this.setState({
            [input]: e.target.value
        });
    }

    handleSetChange = (params)=>{
        
        console.log(params);
        this.setState({
            idCategory: params.id,
            nombre: params.name,
            descripcion: params.description
        });
    }

    handleChangeSelect = input => e =>{
        this.setState({
            [input]: e.target.value,
            [e.target.name]: e._targetInst.memoizedProps.children[0][0]
        });

        console.log(this.state)
    }

    handleChangeCategory = input => e =>{
        
        this.setState({
            [input]: e.target.value,
            [e.target.name]: e._targetInst.memoizedProps.children[0][0],
            name: e._targetInst.memoizedProps.children[0][0]
        });

        console.log(this.state)
    }

    actualizar = ()=>{
        const categoryRef = firebase.firestore().collection('ProductCategories');
        let categorias = [];

        categoryRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
                categorias.push(doc.data());
          });
          this.setState({ categories:categorias })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });
    }

    handleDelete = (selected)=>{
        const refCategory = firebase.firestore().collection("ProductCategories");
        refCategory.doc(selected[0]).update({ status: 'Contactado' },{merge: true}).then((res) => {
          console.log("se actualizó el registro");
        }).catch(err => {
          console.log("no se pudo actualizar el registro");
        });
    
        // console.log(selected[0]);
      }
    
    handleStatus = (selected)=>{
        const refCategory = firebase.firestore().collection("ProductCategories");
        refCategory.doc(selected[0]).update({ status: false },{merge: true}).then((res) => {
          console.log("se actualizó el registro !!!");
    
        }).catch(err => {
          console.log("no se pudo actualizar el registro");
        });
    
        // console.log(selected[0]);
      }

    render(){
        const { nombre, descripcion, idCategory } = this.state;
        const values = { nombre, descripcion, idCategory }

        return(
            <React.Fragment>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12 text-left mb-5">
                            <span className="title">Categorias</span>
                        </div>
                        <div className="col-12">
                        <CategoryForm categories={this.state.categories} handleDelete={this.handleDelete} handleStatus={this.handleStatus} actualizar={this.actualizar} handleEditar={(e) => this.handleEditar(e)} handleSubmit={(e) => this.handleSubmit(e)} handleChange={this.handleChange} handleSetChange={this.handleSetChange} handleChangeSelect={this.handleChangeSelect} handleChangeProduct={this.handleChangeProduct} values={values} productos={this.state.products} handleEdit={this.handleEdit} handleDelete={this.handleDelete} handleStatus={this.handleStatus} actualizar={this.actualizar}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}