import React, { Component } from 'react';
import { render } from "react-dom";
import { HashRouter, Redirect, Switch, Route } from "react-router-dom";
import { BrowserRouter } from 'react-router-dom';
//components
import Header from './Header';
import Home from './Home';
import Somos from './Somos';
import Somos2 from './Somos2';
import Recicla from './Recicla';
import Materiales from './Materiales';
import Products from './Products';
import Centros from './Centros';
import Zonas from './Zonas';
import Categorias from './Categorias';
import Productos from './Productos';
import ConfirmationRedirect from './ConfirmationRedirect';
import Download from './Download';
import Login from './FormLogin';
import Logout from './Logout';
import ForgotPass from './ForgotPass';
import FormRegister from './FormRegister';
import ErrorPage from './404';
import Admin from './Admin';


const isAdmin = () => {
  let userId = localStorage.getItem("userId");
 // return (userId && userId=="gSyuGyar2Datk1zQ6ctnode1cZT2")?true : false;
 if(userId && userId=="gSyuGyar2Datk1zQ6ctnode1cZT2"){
  return true;
}
if(userId && userId=="IicQEwsIk5Y3d5JejIPBRAk0bQI2"){
  return true;
}
if(userId && userId=="ZzOfWaQxlRZckhwJc5Ip881AfbY2"){
  return true;
}

if(userId && userId=="kGhwV0MH0YfZAArdoaFpBcY4hAL2"){
  return true;
}

if(userId && userId=="slxkktNv1VYU4K7wdcfsIZFmsKk2"){
  return true;
}

if(userId && userId=="lPmmMkhqibUByxVNNPW4AUs9oFl1"){
  return true;
}



return false;
}

const privateRoute = (Component,isAdmin) => {
  return (isAdmin())
  ? <Component/>
  : <Redirect to="/" />
}


const Routes = (props) => (
  <BrowserRouter>

      <Route path="/" component={Header}/>
      <Switch>
          <Route path="/" component={Recicla} exact/>
          <Route path="/home" component={Home} exact/>
          <Route path="/recicla" component={Recicla} exact/>
          <Route path="/somos" component={Somos} exact/>
          <Route path="/contactanos" component={Somos2} exact/>
          <Route path="/download" component={Download} exact/>
          <Route path="/materiales" component={Materiales} exact/>
          <Route path="https://ubergreen.cl/materiales" component={Materiales} exact/>
          <Route path="/login" component={Login} exact/>
          <Route path="/logout" component={Logout} exact/>
          <Route path="/forgot-pass" component={ForgotPass} exact/>
          <Route path="/register" component={FormRegister} exact/>
          <Route path="/confirm" component={ConfirmationRedirect} exact/>
          <Route path="/admin" component={()=>privateRoute(Admin,isAdmin)}/>
          <Route path="/solicitudes" component={()=>privateRoute(Admin,isAdmin)}/>
          <Route path="/centros" component={()=>privateRoute(Centros,isAdmin)}/>
          <Route path="/zonas" component={()=>privateRoute(Zonas,isAdmin)}/>
          <Route path="/productos" component={()=>privateRoute(Productos,isAdmin)}/>
          <Route path="/categorias" component={()=>privateRoute(Categorias,isAdmin)}/>
          <Route path="/products" component={()=>privateRoute(Products,isAdmin)}/>
          <Route path="*" component={ErrorPage}/>
      </Switch>
    </BrowserRouter>
  )

class Router extends Component{
    constructor(props){
        super(props);

        this.state={ isAuth:""}
    }

    componentDidMount(){
      var isAuth=false;
      localStorage.getItem('userId')? isAuth=true : isAuth=false;
      this.setState({
        isAuth
      })
    }

    componentWillUnmount(){
      var isAuth=false;
      this.setState({
        isAuth
      })
    }

    render(){
        return(
            <Routes/>
        );
    }
}

export default Router;