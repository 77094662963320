import React, { Component } from 'react';
import Orders from './private/Orders';
import ZonasForm from './private/ZonasForm';
import firebase from './firebase';


export default class Zonas extends Component {
    constructor(props){
        super(props);
    
        this.state = {
            name: "", 
            descripcion: "", 
            delivery: "", 
            longitud: "", 
            zonas: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }

    handleSubmit = (event)=> {
        //event.preventDefault();
        const zonas = [];
        const zonasRef = firebase.firestore().collection("ZoneRegions");
        
        zonasRef.add({
            name: this.state.name,
            description: this.state.descripcion,
            deliveryCost: this.state.delivery,
           // location: new firebase.firestore.GeoPoint(parseFloat(this.state.latitud), parseFloat(this.state.longitud))
        }).then((ref)=>{
            zonasRef.doc(ref.id).update({id: ref.id})    
        }).catch(function(error){
            console.log("Error getting documents");
        });

        zonasRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
                zonas.push(doc.data());
          });
          this.setState({ zonas:zonas })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });
      }

      handleChange = input => e =>{
        this.setState({
            [input]: e.target.value
        });
    }

    handleChangeSelect = input => e =>{
        
        this.setState({
            [input]: e.target.value,
            [e.target.name]: e._targetInst.memoizedProps.children[0][0]
        });
    }

    componentWillMount(){
        const zonas=[];
        const zonasRef = firebase.firestore().collection("ZoneRegions");
        
//array de zonas
        zonasRef.get().then((snapshot)=>{
            snapshot.forEach(function(doc) {
               // console.log(doc.data());
                zonas.push(doc.data());
          });
          this.setState({ zonas:zonas })
        }).catch((error)=>{
           console.log("Error getting documents");     
        });
    }


    render(){

        const { name, descripcion, delivery, longitud, zonas } = this.state;
        const values = { name, descripcion, delivery, longitud, zonas }

        return(
            <React.Fragment>
                <div className="container my-5">
                    <div className="row">
                    <div className="col-12 text-left mb-5">
                            <span className="title">Zonas</span>
                        </div>
                        <div className="col-12">
                        <ZonasForm handleSubmit={this.handleSubmit} handleChange={this.handleChange} handleChangeSelect={this.handleChangeSelect} values={values} zonas={this.state.zonas}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}