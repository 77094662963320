import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import carton from './thumb-carton.png'
import papel from './thumb-papel.png'
import diarios from './thumb-diarios.png'
import aluminios from './thumb-aluminio.png'
import otros from './thumb-otros.png'
const MyButton = styled(Button)({
  background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
   },
  });


export default class Galery extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 2,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

  style = {
    backgroundColor: '#ffffff',
  };

  render() {
    const classes = this.useStyles;
    const style = this.styles;

    return (<React.Fragment>
      <div className='container-fluid' style={this.style}>
        <div className="container">
          <a name="materiales"></a>
          <h1 className="title">Tipos de materiales</h1>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div className="foto-materials">
                  <Paper className={classes.paper}>
                    <img src={carton} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <span className="texto-foto">Cartón</span>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="foto-materials">
                  <Paper className={classes.paper}>
                    <img src={papel} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <span className="texto-foto">Papel oficina</span>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="foto-materials">
                  <Paper className={classes.paper}>
                    <img src={diarios} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <span className="texto-foto">Papeles y cartones</span>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="foto-materials">
                  <Paper className={classes.paper}>
                    <img src={aluminios} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <span className="texto-foto">Chatarra</span>
                    </div>
                  </Paper>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="foto-materials">
                  <Paper className={classes.paper}>
                    <img src={otros} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <span className="texto-foto">Metales</span>
                    </div>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="container-fluid recicla" >
        <div className="row boton-recicla">
          <div className="mx-auto">
            <MyButton>
              <Link to={"/materiales"} style={{ color: "white", textTransform: "capitalize" }}>Ver más ></Link>
            </MyButton>
          </div>
        </div>
      </div>
    </React.Fragment>
    );
  }
}