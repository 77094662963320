import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import plastico from './plasticos.png'
import vidrio from './vidrio_domiciliario.png'
import diarios from './thumb-diarios.png'
import aluminio from './thumb-aluminio.png'
import chatarra from './chatarra_electronica.jpg'
import tetrapack from './Tetrapack.png'
import otros from './thumb-otros.png'
import Doc from './DocService';

const MyButton = styled(Button)({
  background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
   },
  });


export default class Galery extends Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.ref = React.createRef();
  }

  createPdf = (html) => Doc.createPdf(html);

  useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 2,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));

  style = {
    backgroundColor: '#ffffff',
  };

  render() {
    const classes = this.useStyles;
    const style = this.styles;

    return (<React.Fragment>
        <div className="container" ref={this.ref} style={{paddingTop:"100px", paddingBottom:"50px"}}>
          <a name="materiales"></a>
          <h1 className="title">Tipos de materiales</h1>
          <div className={classes.root}>
          <Grid container spacing={3}>
              <Grid item  xs={12} sm={6}>
                <div className="foto-materials">
                    <img src={plastico} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <Hidden smDown>
                      <p id="info2" style={{fontSize:"1.2vw", textAlign:"justify"}}>
                      PLÁSTICO 1: botellas de agua, jugo o bebida, sin tapa y sin etiqueta, envases transparentes donde venden tortas, queques, sushi.
                      <br />
PLÁSTICO 2: envases de útiles de aseo personal (crema, shampoo, etc) y útiles de aseo (cloro, limpia piso, limpia muebles, etc), Sin tapa y vacíos.
<br />
PLÁSTICO 3: envoltorio de papel higiénico, envoltorio plástico de toalla de papel, envoltorio plástico pañales, típica bolsa del pan y verduras.
<br />
PLÁSTICO 4: tapas plásticas de todo tipo a excepción de las rociadoras spray.
                      </p>
                      </Hidden>
                      <Hidden smUp>
                      <p id="info2" style={{fontSize:"3vw", textAlign:"justify"}}>
                      PLÁSTICO 1: botellas de agua, jugo o bebida, sin tapa y sin etiqueta, envases transparentes donde venden tortas, queques, sushi.
                      <br />
PLÁSTICO 2: envases de útiles de aseo personal (crema, shampoo, etc) y útiles de aseo (cloro, limpia piso, limpia muebles, etc), Sin tapa y vacíos.
<br />
PLÁSTICO 3: envoltorio de papel higiénico, envoltorio plástico de toalla de papel, envoltorio plástico pañales, típica bolsa del pan y verduras.
<br />
PLÁSTICO 4: tapas plásticas de todo tipo a excepción de las rociadoras spray.
                      </p>
                      </Hidden>
                      <span className="texto-foto">Plásticos</span>
                    </div>
                </div>
              </Grid>
              <Grid item  xs={12} sm={6}>
              <div className="foto-materials">
                    <img src={vidrio} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <Hidden smDown>
                      <p id="info2" style={{fontSize:"1.2vw", textAlign:"justify"}}>Botellas de alcoholes, botellas de aceite, frascos de perfume, vasos y copas en mal estado, frascos en general.</p>
                      </Hidden>
                      <Hidden smUp>
                      <p id="info2" style={{fontSize:"4vw", textAlign:"justify"}}>Botellas de alcoholes, botellas de aceite, frascos de perfume, vasos y copas en mal estado, frascos en general.</p>
                      </Hidden>
                      <span className="texto-foto">Vidrio domiciliario</span>
                    </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {/*<Grid item xs={6}>
                <div className="foto-materials">
                    <img src="plasticos.png" className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <p id="info2" style={{fontSize:"1.2vw", textAlign:"left"}}>
                      PLÁSTICO 1: botellas de agua, jugo o bebida, sin tapa y sin etiqueta, envases transparentes donde venden tortas, queques, sushi.
                      <br />
PLÁSTICO 2: envases de útiles de aseo personal (crema, shampoo, etc) y útiles de aseo (cloro, limpia piso, limpia muebles, etc), Sin tapa y vacíos.
<br />
PLÁSTICO 3: envoltorio de papel higiénico, envoltorio plástico de toalla de papel, envoltorio plástico pañales, típica bolsa del pan y verduras.
<br />
PLÁSTICO 4: tapas plásticas de todo tipo a excepción de las rociadoras spray.
                      </p>
                      <span className="texto-foto">Plasticos</span>
                    </div>
                </div>
              </Grid>
              <Grid item xs={6}>
              <div className="foto-materials">
                    <img src="vidrio_domiciliario.png" className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <p id="info2">Botellas de alcoholes, botellas de aceite, frascos de perfume, vasos y copas en mal estado, frascos en general.</p>
                      <span className="texto-foto">Vidrio Domiciliario</span>
                    </div>
                </div>
    </Grid>*/}
              <Grid item xs={12} sm={4}>
              <div className="foto-materials">
                    <img src={diarios} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <Hidden smDown>
                      <p id="info2"  style={{fontSize:"1vw", textAlign:"justify"}}>Papel fondo blanco: hojas carta, oficio, hojas de cuadernos, planos. Pueden llegar enteras, rotas, tiritas, etc.

Papel de color mixto: Papel de color, revistas, diario o con alta impresión.

Cartón: todo tipo cajas de remedios, de pasta dental, de puré en polvo, de zapatos, de té, conos de confort y toalla nova, cajas cereales, cajas de todo tipo etc. Importante sin metales u objetos duros de otro tipo.</p>
                      </Hidden>
                      <Hidden smUp>
                      <p id="info2"  style={{fontSize:"3.5vw", textAlign:"justify"}}>Papel fondo blanco: hojas carta, oficio, hojas de cuadernos, planos. Pueden llegar enteras, rotas, tiritas, etc.

Papel de color mixto: Papel de color, revistas, diario o con alta impresión.

Cartón: todo tipo cajas de remedios, de pasta dental, de puré en polvo, de zapatos, de té, conos de confort y toalla nova, cajas cereales, cajas de todo tipo etc. Importante sin metales u objetos duros de otro tipo.</p>
                      </Hidden>
                      <span className="texto-foto">Papeles y cartones</span>
                    </div>
                </div>
              </Grid>
              <Grid item  xs={12} sm={4}>
              <div className="foto-materials">
                    <img src={aluminio} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <Hidden smDown>
                      <p id="info2" style={{fontSize:"1.2vw", textAlign:"justify"}}>Productos de acero que han completado su vida útil, como electrodomésticos, autos, bicicletas, materiales de construcción, herramientas y latas de acero postconsumidor (conservas). Usa un imán para ayudarte a determinar si es chatarra, si adhiere lo es.</p>
                      </Hidden>
                      <Hidden smUp>
                      <p id="info2" style={{fontSize:"3.5vw", textAlign:"justify"}}>Productos de acero que han completado su vida útil, como electrodomésticos, autos, bicicletas, materiales de construcción, herramientas y latas de acero postconsumidor (conservas). Usa un imán para ayudarte a determinar si es chatarra, si adhiere lo es.</p>
                      </Hidden>
                      <span className="texto-foto">Chatarra</span>
                    </div>
                </div>
              </Grid>
              <Grid item  xs={12} sm={4}>
              <div className="foto-materials">
                    <img src={otros}className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <Hidden smDown>
                      <p id="info2"  style={{fontSize:"1vw", textAlign:"justify"}}>El cobre tiene un color brillante, rojizo y claro. Si ha estado expuesto a la intemperie se pondrá verde. El Aluminio es brillante, plateado y suave, lo encontramos en latas de bebida, papel aluminio, perfiles y ventanas. El bronce es una aleación compuesta por cobre y estaño, presente en llaves, candados y picaportes. Usa un imán para establecer si se trata de metales, si NO se adhiere se encuentra en esta categoría.</p>
                      </Hidden>
                      <Hidden smUp>
                      <p id="info2"  style={{fontSize:"3.5vw", textAlign:"justify"}}>El cobre tiene un color brillante, rojizo y claro. Si ha estado expuesto a la intemperie se pondrá verde. El Aluminio es brillante, plateado y suave, lo encontramos en latas de bebida, papel aluminio, perfiles y ventanas. El bronce es una aleación compuesta por cobre y estaño, presente en llaves, candados y picaportes. Usa un imán para establecer si se trata de metales, si NO se adhiere se encuentra en esta categoría.</p>
                      </Hidden>
                      <span className="texto-foto">Metales</span>
                    </div>
                </div>
              </Grid>
              {/****************************** *
              <Grid item xs={4}>
              <div className="foto-materials">
                    <img src="thumb-diarios.png" className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <p id="info2">Envases de tetrapack , enjuagadas y aplastadas</p>
                      <span className="texto-foto">Papeles y cartones</span>
                    </div>
                </div>
              </Grid>
              <Grid item xs={4}>
              <div className="foto-materials">
                    <img src="thumb-aluminio.png" className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <p id="info2">Envases de tetrapack , enjuagadas y aplastadas</p>
                      <span className="texto-foto">Chatarra</span>
                    </div>
                </div>
              </Grid>
              <Grid item xs={4}>
              <div className="foto-materials">
                    <img src="thumb-otros.png" className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <p id="info2">Envases de tetrapack , enjuagadas y aplastadas</p>
                      <span className="texto-foto">Metales</span>
                    </div>
                </div>
              </Grid>
              ****************************** */}
            </Grid>
            <Grid container spacing={3}>
              <Grid item  xs={12} sm={6}>
                <div className="foto-materials">
                    <img src={chatarra} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <Hidden smDown>
                      <p id="info2" style={{fontSize:"1.2vw", textAlign:"justify"}}>Aparatos electrónicos en desuso Notebook, PC, DVD, tablet, smartphone, sin baterías</p>
                      </Hidden>
                      <Hidden smUp>
                      <p id="info2" style={{fontSize:"3.5vw", textAlign:"justify"}}>Aparatos electrónicos en desuso Notebook, PC, DVD, tablet, smartphone, sin baterías</p>
                      </Hidden>
                      <span className="texto-foto">Chatarra electrónica</span>
                    </div>
                </div>
              </Grid>
              <Grid item  xs={12} sm={6}>
              <div className="foto-materials">
                    <img src={tetrapack} className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <Hidden smDown>
                      <p id="info2" style={{fontSize:"1.2vw", textAlign:"justify"}}>Envases de tetrapack , enjuagadas y aplastadas</p>
                      </Hidden>
                      <Hidden smUp>
                      <p id="info2" style={{fontSize:"3.5vw", textAlign:"justify"}}>Envases de tetrapack , enjuagadas y aplastadas</p>
                      </Hidden>
                      <span className="texto-foto">Tetrapack</span>
                    </div>
                </div>
              </Grid>
              </Grid>
             {/* <Grid container spacing={3}>
              <Grid item xs={6}>
                <div className="foto-materials">
                    <img src="chatarra_electronica.jpg" className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <p id="info2">Aparatos electrónicos en desuso Notebook, PC, DVD, tablet, smartphone, sin baterías</p>
                      <span className="texto-foto">Chatarra Electrónica</span>
                    </div>
                </div>
              </Grid>
              <Grid item xs={6}>
              <div className="foto-materials">
                    <img src="Tetrapack.png" className="img-fluid" alt="Responsive"></img>
                    <div className="mascara">
                      <p id="headline"></p>
                      <p id="info2">Envases de tetrapack , enjuagadas y aplastadas</p>
                      <span className="texto-foto">Tetrapack</span>
                    </div>
                </div>
              </Grid>
            </Grid>*/}
          </div>
        </div>
    </React.Fragment>
    );
  }
}