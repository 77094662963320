import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DetalleRecicle from './DetalleRecicle';
const useStyles = makeStyles(theme => ({
  fab: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function FloatingActionButtons(props) {
  const classes = useStyles();
  const { detalleRecicle,userOrder, handleAdd, selectedCenter,handleChange,values } = props;


  //console.log(detalleRecicle);



  return (
    detalleRecicle.length > 0 ? (
      <div>
      <Fab color="primary" aria-label="add" className={classes.fab} style={{marginBottom:"30px",marginTop:"60px"}}
      onClick={handleAdd}>
        <AddIcon />
      </Fab> <span style={{paddingTop:"10px !important"}}>Agregar material</span>
      <DetalleRecicle detalleRecicle={detalleRecicle} userOrder={userOrder} selectedCenter={selectedCenter} handleChange={handleChange} values={values}/>
    </div>
    ):(
      <div>
      <Fab color="primary" aria-label="add" className={classes.fab} style={{marginBottom:"30px",marginTop:"60px"}}
      onClick={handleAdd}>
        <AddIcon />
      </Fab> <span style={{paddingTop:"10px !important"}}>Agregar material</span>
      <DetalleRecicle detalleRecicle={detalleRecicle} userOrder={userOrder} selectedCenter={selectedCenter} handleChange={handleChange} values={values}/>
    </div>
    )
  );
}