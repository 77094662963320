import React, { Component } from 'react';
//components
import BannerShort from './BannerShort';
import ContactForm from './ContactForm';
import Footer from './Footer';
import Hidden from '@material-ui/core/Hidden';
import somos from './somos.png'
import somos1 from './somos1.png'
import somos2 from './somos2.png'
export default class Somos extends Component{
    constructor(props){
        super(props)

        this.state = {}
    }

    render(){
        return(
            <React.Fragment>
            <BannerShort title="Somos" image={somos}/>
            <div className='container-fluid text-center' style={{backgroundColor:"#ffffff"}}>

                <div className="container text-center">
                    <div className="row">
                        <div className="col" id="contactanos">
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </div>    
            <Footer/>
            </React.Fragment>
        )
    }
}