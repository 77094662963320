import React from 'react';
import './css/General.css';
import './css/index.css';
//components
import Router from './router';
import { BrowserRouter,Route } from 'react-router-dom';


function App() {

  return (
      <div style={{backgroundColor: "#ffffff"}}>
        <BrowserRouter>
          <Router/>
        </BrowserRouter>
      </div>
  );
}

export default App;
