import React, { Component } from 'react';
//components
import BannerShort from './BannerShort';
import GaleryPage from './GaleryPage';
import Footer from './Footer';
import somos_1 from './somos.png';

export default class Somos extends Component{
    constructor(props){
        super(props)

        this.state = {}
    }

    render(){
        return(
            <React.Fragment>
            <BannerShort title="Materiales" image={somos_1}/>
            <div className='container-fluid text-center' style={{backgroundColor:"#ffffff"}}>
            <GaleryPage/>
            </div>    
            <Footer/>
            </React.Fragment>
        )
    }
}