import React, { Component } from 'react';
//components
import BannerShort from './BannerShort';
import ContactForm from './ContactForm';
import Footer from './Footer';
import Hidden from '@material-ui/core/Hidden';
import somos from './somos.png'
import somos1 from './somos1.png'
import somos2 from './somos2.png'
export default class Somos extends Component{
    constructor(props){
        super(props)

        this.state = {}
    }

    render(){
        return(
            <React.Fragment>
            <BannerShort title="Somos" image={somos}/>
            <div className='container-fluid text-center' style={{backgroundColor:"#ffffff"}}>
            <div className="container text-center">
                    <div className="row">
                        <div className="d-sm-block d-md-none  col-md-12 text-left" style={{paddingTop:"20px",paddingBottom:"0px"}}>
                            <span className="" style={{fontSize:"4vw"}}>Equipo</span>
                        </div>
                        <Hidden smDown>
                        <div className="d-sm-none d-md-block  col-md-6 text-right" style={{paddingTop:"20px",paddingBottom:"0px"}}>
                            <span className="" style={{fontSize:"3vw"}}>Equipo</span>
                        </div>
                        </Hidden>
                        <div className="col-sm-12 col-md-6" style={{paddingTop:"10px",paddingBottom:"0px"}}>
                            <Hidden smDown>
                            <p className="text-left" style={{fontSize:"1.7vw",paddingTop:"20px",paddingBottom:"20px"}}>En  Ubergreen    somos  
                            un  equipo  de  emprendedores  sociales  comprometidos  con  la  visión  de cambiar  el  paradigma  del  reciclaje  
                            y  lo  hacemos  aplicando  los  principios  de  innovación  y  mejora continua.   Durante   años   hemos   estado   
                            presentantes   en   la   industria   del   reciclaje   logrando sostenibilidad,  valorizando  gran  cantidad  de  
                            toneladas  anualmente,  hoy  nuestro  equipo  ha desarrollado  una  manera  diferente  de  conectar  a  las  
                            personas,  empresas  e  instituciones  con  el reciclaje. </p>
                            </Hidden>
                            <Hidden smUp>
                            <p className="text-left" style={{fontSize:"4vw",paddingTop:"20px",paddingBottom:"20px"}}>En  Ubergreen    somos  
                            un  equipo  de  emprendedores  sociales  comprometidos  con  la  visión  de cambiar  el  paradigma  del  reciclaje  
                            y  lo  hacemos  aplicando  los  principios  de  innovación  y  mejora continua.   Durante   años   hemos   estado   
                            presentantes   en   la   industria   del   reciclaje   logrando sostenibilidad,  valorizando  gran  cantidad  de  
                            toneladas  anualmente,  hoy  nuestro  equipo  ha desarrollado  una  manera  diferente  de  conectar  a  las  
                            personas,  empresas  e  instituciones  con  el reciclaje. </p>
                            </Hidden>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                        <img src={somos1} className="img-fluid" alt="Responsive image"></img>
                        </div>
                        <div className="col-6">
                        <img src={somos2} className="img-fluid" alt="Responsive image"></img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-sm-block d-md-none  col-md-12 text-left" style={{paddingTop:"20px",paddingBottom:"0px"}}>
                            <span className="" style={{fontSize:"4vw"}}>Compromiso</span>
                        </div>
                        <Hidden smDown>
                        <div className="d-sm-none d-md-block  col-md-6 text-right" style={{paddingTop:"20px",paddingBottom:"0px"}}>
                            <span className="" style={{fontSize:"3vw"}}>Compromiso</span>
                        </div>
                        </Hidden>
                        <div className="col-sm-12 col-md-6" style={{paddingTop:"10px",paddingBottom:"0px"}}>
                        <Hidden smDown>
                        <p className="text-left" style={{fontSize:"1.7vw",paddingTop:"20px"}}>Creemos con firmeza   en un cambio hacia 
                            una economía eficiente en el uso de los recursos, para esto  buscamos   conectar  a  las  fuentes  de  
                            generación  de  residuos  con  empresas  recicladoras  de modo de aumentar las cuotas de reciclaje en 
                            la región y el país ¡este es  nuestro compromiso!.</p>
                        </Hidden>
                        <Hidden smUp>
                        <p className="text-left" style={{fontSize:"4vw",paddingTop:"20px"}}>Creemos con firmeza   en un cambio hacia 
                            una economía eficiente en el uso de los recursos, para esto  buscamos   conectar  a  las  fuentes  de  
                            generación  de  residuos  con  empresas  recicladoras  de modo de aumentar las cuotas de reciclaje en 
                            la región y el país ¡este es  nuestro compromiso!.</p>
                        </Hidden>
                        </div>
                    </div>
                </div>

            </div>    
            <Footer/>
            </React.Fragment>
        )
    }

}