import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


const config = {
    apiKey: "AIzaSyCnG7MQnilXLO9iykjLIQ_DyjQDP6BJ87w",
    authDomain: "ubergreen-c4174.firebaseapp.com",
    databaseURL: "https://ubergreen-c4174.firebaseio.com",
    projectId: "ubergreen-c4174",
    storageBucket: "ubergreen-c4174.appspot.com",
    messagingSenderId: "598153174822",
    appId: "1:598153174822:web:9a835dc38d6e58d8"
};

firebase.initializeApp(config);

export default firebase;





