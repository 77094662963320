import React, { Component } from 'react';
import { styled, withStyles, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const MyButton = styled(Button)({
  background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  '&:hover': {
    background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
 },
});


export default class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      comentario: "",
      email: "",
      timePassed: false,
      display: "none"
    }

    this.handleChange = this.handleChange.bind(this);
  }


  useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '200px',
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
    root: {
      flexGrow: 2,
    },
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },

  }));

  style = {
    backgroundColor: '#ffffff',
    paddingTop: '80px',
    height: '600px'
  };

  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  }

  setTimePassed() {
    this.setState({ timePassed: false });
  }


  handleSendMail = (e) => {
    e.preventDefault();
    const { name, email, comentario } = this.state;
    if(name === ""){
      return alert("Debe completar el campo nombre");
    }
    if(email === ""){
      return alert("Debe completar el campo email");
    }
    if(comentario === ""){
      return alert("Debe completar el campo comentario");
    }
    
    this.setState({ timePassed: true })
    setTimeout(() => { this.setTimePassed() }, 3000);

    
    const data = {
      name: name,
      email: email,
      message: comentario
    }

    fetch("https://us-central1-ubergreen-c4174.cloudfunctions.net/contactanos", {
            body: JSON.stringify(data),
            method: 'POST',
            crossDomain: true,
            headers:{
                "Content-Type": "application/json"
            }               
        })
        .then(async res => {
        const value = await res.json();
        console.log(value);
        this.setState({
          email:"",
          name:"",
          comentario:"",
          display: "block"
        });
        }).catch((error)=>{
            console.log("Error agregando ordenes");
        });
    setTimeout(() => { this.setState({display:"none"}) }, 8000);
    console.log(data);
  }

  CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'green',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        },
      },
    },
  })(TextField);


  theme = createMuiTheme({
    palette: {
      primary: green,
    },
  });

  CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#1BE9B6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#1BE9B6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1BE9B6',
        },
      },
    },
  })(TextField);
  render() {
    const classes = this.useStyles;
    const classes2 = this.CssTextField;
    const style = this.styles;
    const CssTextField = this.CssTextField;

    return (
      <div className='container-fluid' style={this.style}>
        <div className="container">
          <a name="contactanos"></a>
          <h1 className="title">Contáctanos</h1>

          {(this.state.timePassed) ? (
            <div className="text-center">
              <img src="loader_puntos.gif" className="img-fluid" alt="Responsive image"></img>
            </div>
          ) : (<React.Fragment>
            <form onSubmit={this.handleSendMail}>
            <div className="row">
              <div className="col-md-5">
              <label htmlFor="inputName">Nombre</label>
                <div className="input-group mb-2 mr-sm-2">
                  <input name="name" type="text" className="input-contact-user form-control" id="inlineFormInputGroupUsername2" placeholder="" onChange={this.handleChange}/>
                  </div>
              </div>
              <div className="col-md-5">
              <label htmlFor="inputEmail">Email</label>
                <div className="input-group mb-2 mr-sm-2">
                  <input name="email" type="text" className="input-contact-mail form-control" id="inlineFormInputGroupUsername2" placeholder="" onChange={this.handleChange}/>
                </div>
              </div>
              <div className="col-md-10">
              <div className="input-group mb-2 mr-sm-2">
              <textarea name="comentario" className="form-control input-message" id="exampleFormControlTextarea1" placeholder="Mensaje..." onChange={this.handleChange}></textarea>
                </div>
              </div>
              <div className="col-md-10">
              <div className="input-group mb-2 mr-sm-2" style={{display:this.state.display}}>
              <p className="contacto"  style={{paddingTop:"10px", textAlign: "center",fontWeight:"bold",fontSize:"1vw", color:"#28B463"}}>¡Excelente, mensaje enviado con exito. Desde Ubergreen nos pondremos en contacto con usted!</p>
                </div>
              </div>
            </div>
            <div className="col-10">
                        <div className="row boton-recicla">
                            <div className="mx-auto">
                                <MyButton type="submit">Enviar</MyButton>
                            </div>
                        </div>
                        </div>
            </form>
          </React.Fragment>
            )}
        </div>
      </div>
    );
  }
}