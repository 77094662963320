import React, { Component } from 'react';
import { Link,withRouter } from "react-router-dom"
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Scrollchor from 'react-scrollchor';
import logo from './logo.jpeg';
import firebase from './firebase';


import Profile from './Profile';

const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 38,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
     },
});

class Header extends Component {
    constructor(props) {
        super(props)
        let isAdmin = true;
        const userId = localStorage.getItem('userId');
        userId == null ? isAdmin = false:isAdmin=true;
        this.state = {
            isAdmin
        }
    }

    isAdmin = () => {
        let userId = localStorage.getItem("userId"); 
        if(userId && userId=="gSyuGyar2Datk1zQ6ctnode1cZT2"){
            return true;
        }
        if(userId && userId=="IicQEwsIk5Y3d5JejIPBRAk0bQI2"){
            return true;
        }
        if(userId && userId=="ZzOfWaQxlRZckhwJc5Ip881AfbY2"){
            return true;
        }

        if(userId && userId=="slxkktNv1VYU4K7wdcfsIZFmsKk2"){
            return true;
        }

        if(userId && userId=="lPmmMkhqibUByxVNNPW4AUs9oFl1"){
            return true;
        }
        
        return false;
    }


    logout = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("token");
        localStorage.clear(); 
        this.setState({
            isAdmin:false
        })
        this.props.history.push('/');
    }

    render() {
        let isLogged = localStorage.getItem("userId");
        let isAdmin = this.isAdmin();
        const { match, location, history } = this.props;
       // console.log(location.pathname);
        var user = firebase.auth().currentUser;
        var name, email, photoUrl, uid, emailVerified;
        if (user != null) {
            name = user.displayName;
            email = user.email;
            photoUrl = user.photoURL;
            emailVerified = user.emailVerified;
            uid = user.uid;  // The user's ID, unique to the Firebase project. Do NOT use
            // this value to authenticate with your backend server, if
            // you have one. Use User.getToken() instead.
        }

        var loged_name = name;
        var loged_mail = email;

        const hsstyle = {fontFamily:'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";'}

        return (
            <React.Fragment>

            <div id="inicio"></div>
            <div>
            <a name="inicio" href="#inicio"></a>
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg navbar-light bg-white">
                        <Link to={'/'}>
                            <div className="navbar-brand">

                                <img src={logo} className="img-fluid logo" alt="Responsive image"></img>
                            </div>
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto sombra">
                                {(isAdmin)?(
                                    <React.Fragment>
                                        <li className="nav-item">
                                    <Link to={'/solicitudes'} className="nav-link">Solicitudes</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link to={'/centros'} className="nav-link">Centros</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link to={'/products'} className="nav-link">Productos</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link to={'/categorias'} className="nav-link">Categorias</Link>
                                    </li>
                                    
                                    </React.Fragment>
                                ):(
                                    <React.Fragment>
                                        <li className="nav-item">
                                    <Link  className="nav-link" to={'/somos'}>
                                        Somos
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={'/recicla'}>
                                            Recicla
                                    </Link>
                                </li>
                                <li className="nav-item">

                                    {location.pathname != "/" ? (
                                        <Link to={"/materiales"} className="nav-link">Materiales</Link>
                                    ):(
                                        <Scrollchor to={"#materiales"} className="nav-link">Materiales</Scrollchor>
                                    )}
                                    
                                </li>
                                <li className="nav-item">
                                    <Link to="/contactanos" className="nav-link">Contáctanos</Link>

                                </li>

                                    </React.Fragment>
                                )}
                            </ul>


                            {(isLogged!=null && loged_mail!=null)?(
                                <h5 className="nav-link" style={hsstyle}>Hola {email}&nbsp;</h5>
                            ):(
                                <h5></h5>
                            )}
                            {(isLogged!=null && loged_name!=null)?(
                                <h5 className="nav-link" style={hsstyle}>Hola {name}&nbsp;</h5>
                            ):(
                                <h5></h5>
                            )}
                            {(isLogged!=null)?(
                                <MyButton onClick={this.logout}>SALIR</MyButton>
                            ):(
                                <Link to={'/Login'}>
                            <MyButton style={{textTransform: "capitalize"}}>INGRESA</MyButton>
                            </Link>
                            )}
                        </div>
                    </nav>
                </div>
            </div>
        </React.Fragment>
        );
    }
}

export default withRouter(Header);