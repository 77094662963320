import React from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import firebase from "./firebase";
import { LocalStorage } from "node-localstorage";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const GreenRadio = withStyles({
  root: {
      color: "#333333",
      '&$checked': {
          color: "#1BE9B6",
      },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const rows = [];

var formatNumber = {
  separador: ".", // separador para los miles
  sepDecimal: ',', // separador para los decimales
  formatear:function (num){
  num +='';
  var splitStr = num.split('.');
  var splitLeft = splitStr[0];
  var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
  var regx = /(\d+)(\d{3})/;
  while (regx.test(splitLeft)) {
  splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
  }
  return this.simbol + splitLeft +splitRight;
  },
  new:function(num, simbol){
  this.simbol = simbol ||'';
  return this.formatear(num);
  }
 }

/*export default function SimpleTable(props) {
  const classes = useStyles();
  const { detalleRecicle,userOrder, selectedCenter, handleChange,values } = props;



  var tipo = <h4>Seleccione el centro de acopio</h4>;

    //console.log(detalleRecicle);
    const arr1 = detalleRecicle.filter(d => d.centro === selectedCenter);
    localStorage.setItem('finalOrder', JSON.stringify(arr1));
    var descuento = "";
    values.tipo == "retiro" ? descuento = 3000 : descuento = 0;
    //console.log(selectedCenter);
    //console.log(detalleRecicle[0]);
    var detalleRecicle3 = detalleRecicle.sort(function(a, b) {
      return parseFloat(a.distancia) - parseFloat(b.distancia);
    });
    //console.log(detalleRecicle3);
    if(values.tipo == "retiro"){
      var detalleRecicle2 = [];
      for ( var i = 0, l = detalleRecicle3.length, bar = []; i < 1; i++ ) {
        detalleRecicle2[ i ] = detalleRecicle3[ i ];
     }
    }else{
      var detalleRecicle2 = [];
      detalleRecicle3.map((row,i) => {
        detalleRecicle2.push(row);
      });
    }
  return (
      <div>
      {(detalleRecicle3.length > 0)?(tipo):("")}
      {(detalleRecicle3.length > 0)?(
        <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Productos</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Centro</TableCell>
            <TableCell align="right">distancia</TableCell>
            <TableCell align="right">Ganancia Aproximado</TableCell>
            <TableCell align="right">Selecciona</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {detalleRecicle2.map((row,i) => (
            <TableRow key={i}>
              <TableCell component="th" scope="row">{row.material}</TableCell>
              <TableCell align="right">{row.cantidad} {row.medida}</TableCell>
              <TableCell component="th" align="right">{row.centro}</TableCell>
              <TableCell component="th" align="right">{formatNumber.new(((row.distancia/1000)/1000).toFixed(3))} Kms</TableCell>
              <TableCell align="right">{formatNumber.new((row.valor*row.cantidad)-descuento, "$")}</TableCell>
              <TableCell align="right">
                <GreenRadio
                  checked={row.centro == selectedCenter}
                  onChange={handleChange('selectedCenter')}
                  value={row.centro}
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': row.centro }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
      ):("")
      }</div>
  );
}*/

export default function ExtendedTable(props) {
  const classes = useStyles();
  const { detalleRecicle,userOrder, selectedCenter, handleChange,values } = props;

  var tipo = <h4>Seleccione el centro de acopio</h4>;

  // FILTRA EL CENTRO DE ACOPIO   const arr1 = detalleRecicle.filter(d => d.centro === selectedCenter);
  var arr1 =[];
  values.tipo == "retiro" ? arr1 = detalleRecicle : arr1 = detalleRecicle.filter(d => d.centro === selectedCenter);



  localStorage.setItem('finalOrder', JSON.stringify(arr1));
  var descuento = "";
  values.tipo == "retiro" ? descuento = 3000 : descuento = 0;
  //console.log(selectedCenter);


  var detalleRecicle3 = detalleRecicle.sort(function(a, b) {
    return parseFloat(a.distancia) - parseFloat(b.distancia);
  });
  if(values.tipo == "retiro"){
    var detalleRecicle2 = [];
    for ( var i = 0, l = detalleRecicle3.length, bar = []; i < 1; i++ ) {
      detalleRecicle2[ i ] = detalleRecicle3[ i ];
    }
  }else{
    var detalleRecicle2 = [];
    detalleRecicle3.map((row,i) => {
      detalleRecicle2.push(row);
    });
  }
  var centros = [];
  var citiesRef =firebase.firestore().collection('ReciclerCenters');
  var query = citiesRef.get().then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        snapshot.forEach(doc => {

         centros.push(doc.data());
        });

      })
      .catch(err => {
        console.log('Error getting documents', err);
      });


  return (
      <div>
        {(detalleRecicle3.length > 0)?(tipo):("")}

        {(detalleRecicle3.length > 0 && values.tipo == 'entrega')?(
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Productos</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell align="right">Centro</TableCell>
                    <TableCell align="right">distancia</TableCell>
                    <TableCell align="right">Ganancia Aproximado</TableCell>
                    <TableCell align="right">Selecciona</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detalleRecicle3.map((row,i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">{row.material}</TableCell>
                        <TableCell align="right">{row.cantidad} {row.medida}</TableCell>
                        <TableCell component="th" align="right">{row.centro}</TableCell>
                        <TableCell component="th" align="right">{formatNumber.new(((row.distancia/1000)/1000).toFixed(3))} Kms</TableCell>
                        <TableCell align="right">{formatNumber.new((row.valor*row.cantidad)-descuento, "$")}</TableCell>
                        <TableCell align="right">
                          <GreenRadio
                              checked={row.centro == selectedCenter}
                              onChange={handleChange('selectedCenter')}
                              value={row.centro}
                              name="radio-button-demo"
                              inputProps={{ 'aria-label': row.centro }}
                          />
                        </TableCell>
                      </TableRow>
                  ))}

                </TableBody>
              </Table>
            </Paper>
        ):("Debe ingresar al menos 1 producto.")
        }
        {(detalleRecicle3.length > 0 && values.tipo == 'retiro')?(
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Productos</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell align="right">Ganancia Aproximado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detalleRecicle3.map((row,i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">{row.material}</TableCell>
                        <TableCell align="right">{row.cantidad} {row.medida}</TableCell>
                        <TableCell align="right">{formatNumber.new((row.valor*row.cantidad), "$")}</TableCell>


                      </TableRow>
                  ))}

                </TableBody>
              </Table>
            </Paper>
        ):("Debe ingresar al menos 1 producto.")
        }
      </div>
  );
}