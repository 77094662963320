import React, { Component } from 'react';
import firebase from './firebase';
//components
import BannerShort from './BannerShort';
import StepperHorizontal from './Stepper';
import Header from './Header';
import { reject } from 'q';
import Footer from './Footer';
import recicla from './Imageweather.png'

const zoneRegions = firebase.firestore().collection('ZoneRegions');

const regiones = zoneRegions.get();

export default class Recicla extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    style = { backgroundColor: '#ffffff' };
    render(){
        regiones.then((data)=>{
        //    console.log(data.docs[0].data().points)
        }).catch((error)=>{
            console.log(error)
        });
        //console.log(this.props.location);
        return(
            <React.Fragment>
            <BannerShort title="Recicla" image={recicla}/>
            <div className='container-fluid text-center' style={this.style}>
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                        <StepperHorizontal ruta={this.props.location}/>
                        </div>
                    </div>
                </div>
            </div>    
            <Footer/>
            </React.Fragment>
        )
    }
}