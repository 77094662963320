import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import Link from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import firebase from './firebase';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Footer from './Footer';
import BannerShort from './BannerShort';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}

            UberGreen
            {' '}
            {new Date().getFullYear()}
            {'.'}


        </Typography>
    );
}

const styles = {
    '@global': {
        body: {
            backgroundColor: 'white',
        },
    },
    paper: {
        backgroundColor: 'white',
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    Grid: {
        alignItems: 'center',
    },
    avatar: {
        margin: 1,
        backgroundColor: 'white',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 1,
    },
    submit: {
        margin: '5px 0px 15px',
        backgroundColor: '#1BE9B6',
        color: 'white',
        height: '50px',
        '&:hover': {
            background: "rgb(19, 179, 139)"
        }
    },
    submit2: {
        margin: '5px 0px 5px',
        backgroundColor: '#3b5998',
        color: 'white',
        height: '50px',
        '&:hover': {
            background: "#2f55a4",
        },
    },
    submit3: {
        margin: '5px 0px 5px',
        backgroundColor: '#db4a39',
        color: 'white',
        height: '50px',
        '&:hover': {
            background: "#db4a39",
        },
    },
    signin: {
        color: '#aaaaaa'
    }

};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },

}));

class LoginPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: "",
            errorTextEmail: "",
            errorTextPassword: "",
            auth: "",
            validation: "",
            message: "",
            error: true,
            ruta: "login"
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);

    }

    handleRefresh = () => {
        this.props.history.push("/");
    }

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        return token && token.length > 10;
    }

    handleChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value,
            validation: "",
            open: false
        });

        if (value != "") {
            this.setState({
                error: false
            })
        }
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    submitGoogle = (event) => {
        var provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('email');
        const app = this;
        firebase.auth().signInWithPopup(provider).then(function (result) {
            var token = result.credential.accessToken;
            var user = result.user;
            if (user) {
                firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
                    const refUser = firebase.firestore().collection('users').doc(user.uid);
                    refUser.get().then(res => {
                        if (res.exists) {
                            console.log(res);

                            const username = res.data().name;
                            const email = res.data().email;
                            const phone = res.data().phone;
                            localStorage.setItem('username', username);
                            localStorage.setItem('email', email);
                            localStorage.setItem('phone', phone);
                        } else {
                            localStorage.setItem('username', user.displayName);
                            localStorage.setItem('email', firebase.auth().currentUser.providerData[0].email);
                            localStorage.setItem('phone', user.phoneNumber);

                            const data = { 
                                name: user.displayName, 
                                email: firebase.auth().currentUser.providerData[0].email, 
                                phone: user.phoneNumber, 
                                uid: user.uid, 
                                source: "facebook" 
                            };

                            fetch("https://us-central1-ubergreen-c4174.cloudfunctions.net/register", {
                                body: JSON.stringify(data),
                                method: 'POST',
                                crossDomain: true,
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }).then(async res => {
                                const value = await res.json();
                                    app.setState({});
                            })
                        }

                    }).catch(err => {
                        console.log(err)
                    })
                    localStorage.setItem('token', idToken);
                    localStorage.setItem('userId', user.uid);

                    app.setState({});

                }).catch(function (error) {
                    // Handle error
                });
            } else {
                // No user is signed in.
            }
        }).catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
        });
    }

    submitFacebook = (event) => {
        var provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope('email');
        const app = this;
        firebase.auth().signInWithPopup(provider).then(function (result) {
            var token = result.credential.accessToken;
            var user = result.user;
            if (user) {
                firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
                    const refUser = firebase.firestore().collection('users').doc(user.uid);
                    refUser.get().then(res => {
                        if (res.exists) {
                            const username = res.data().name;
                            const email = res.data().email;
                            const phone = res.data().phone;
                            localStorage.setItem('username', username);
                            localStorage.setItem('email', email);
                            localStorage.setItem('phone', phone);
                        } else {
                            localStorage.setItem('username', user.displayName);
                            localStorage.setItem('email', firebase.auth().currentUser.providerData[0].email);
                            localStorage.setItem('phone', user.phoneNumber);

                            const data = { 
                                name: user.displayName, 
                                email: firebase.auth().currentUser.providerData[0].email, 
                                phone: user.phoneNumber, 
                                uid: user.uid, 
                                source: "facebook" 
                            };

                            fetch("https://us-central1-ubergreen-c4174.cloudfunctions.net/register", {
                                body: JSON.stringify(data),
                                method: 'POST',
                                crossDomain: true,
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            }).then(async res => {
                                const value = await res.json();
                                    app.setState({});
                            })
                        }

                    }).catch(err => {
                        console.log(err)
                    })
                    localStorage.setItem('token', idToken);
                    localStorage.setItem('userId', user.uid);

                    app.setState({});

                }).catch(function (error) {
                    // Handle error
                });
            } else {
                // No user is signed in.
            }
        }).catch(function (error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
        });
    }

    submitForm = (event) => {
        event.preventDefault();


        if (this.state.email != "") {
            this.setState({ errorTextEmail: '', error: false })
        } else {
            this.setState({ errorTextEmail: 'Debe indicar el Email', error: true })
        }

        if (this.state.password != "") {
            this.setState({ errorTextPassword: '', error: false })
        } else {
            this.setState({ errorTextPassword: 'Debe indicar el Password', error: true })

        }

        const app = this;
        if (!this.state.error) {
            firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(function (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                app.setState({
                    validation: error.message,
                    open: true,
                    //message: error.message
                    message: "Usuario o clave invalida"
                });
            });
        }


        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                console.log(user);
                firebase.auth().currentUser.getIdToken(true).then(function (idToken) {

                    const refUser = firebase.firestore().collection('users').doc(user.uid);
                    refUser.get().then(res => {
                        const username = res.data().name;
                        const email = res.data().email;
                        const phone = res.data().phone;
                        localStorage.setItem('username', username);
                        localStorage.setItem('email', email);
                        localStorage.setItem('phone', phone);
                    }).catch(err => {
                        console.log(err)
                    })
                    localStorage.setItem('token', idToken);
                    localStorage.setItem('userId', user.uid);

                    app.setState({});

                }).catch(function (error) {
                    // Handle error
                });
            } else {
                // No user is signed in.
            }

        });

    }

    render() {
        const classes = this.useStyles1;
        if (!localStorage.getItem("token")) {
            firebase.auth().signOut();
        }

        var ruta = 1;
        var enlace = "/";
        var detalleRecicle = 0;
        if (localStorage.getItem("detalleRecicle") != undefined) {
            detalleRecicle = localStorage.getItem("detalleRecicle").length;
        }
        if (detalleRecicle < 1) {
            ruta = 1;
            enlace = "/";
        } else {
            ruta = 2;
            enlace = "/recicla";
        }

        const isAlreadyAuthenticated = this.isAuthenticated();
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div style={{ marginTop: "150px", paddingBottom: "100px" }}>
                        {isAlreadyAuthenticated ? <Redirect to={{ pathname: enlace, state: { id: ruta } }} /> : (
                            <Container component="main" maxWidth="xs">
                                <CssBaseline />
                                <Snackbar
                                    style={{ backgroundColor: "#f44336", color: 'coral' }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    open={this.state.open}
                                    autoHideDuration={6000}
                                    onClose={this.handleClose}
                                    variant="success"
                                    action={[
                                        <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
                                            <CloseIcon />
                                        </IconButton>,
                                    ]}
                                >
                                    <SnackbarContent style={{
                                        backgroundColor: '#f44336',
                                    }}
                                        message={<span id="client-snackbar">{this.state.message}</span>}
                                    />
                                </Snackbar>

                                <div className={this.props.classes.paper}>
                                    <form className={this.props.classes.form} noValidate onSubmit={this.submitForm.bind(this)}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email"
                                            label="Email"
                                            name="email"
                                            autoComplete="email"
                                            value={this.state.email}
                                            autoFocus
                                            onChange={this.handleChange}
                                            error={this.state.errorTextEmail.length === 0 ? false : true}
                                            helperText={this.state.errorTextEmail}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Contraseña"
                                            type="password"
                                            id="password"
                                            value={this.state.password}
                                            autoComplete="current-password"
                                            onChange={this.handleChange}
                                            error={this.state.errorTextPassword.length === 0 ? false : true}
                                            helperText={this.state.errorTextPassword}
                                        />
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="outlined"
                                            //variant={this.props.classes.submit2}
                                            color="primary"
                                            className={this.props.classes.submit2}
                                            onClick={this.submitFacebook}
                                        >
                                            Ingresar con Facebook
                            </Button>
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="outlined"
                                            //variant={this.props.classes.submit3}
                                            color="secondary"
                                            className={this.props.classes.submit3}
                                            onClick={this.submitGoogle}
                                        >
                                            Ingresar con Google
                            </Button>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            color="primary"
                                            className={this.props.classes.submit}
                                        >
                                            Ingresa
                    </Button>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Link to={"/forgot-pass"} variant="body2" align="center">
                                                    Olvidaste tu Contraseña?
                            </Link>
                                            </Grid>
                                            <Grid item>
                                                <Link to={"/register"} variant="body2">
                                                    {"No tienes una cuenta UberGreen? Registrate"}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                                <Box mt={8}>
                                    <Copyright />
                                </Box>
                            </Container>
                        )

                        }</div>
                </div>
                <Footer />
            </React.Fragment>);

    }
}

export default withStyles(styles)(LoginPage);