import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import firebase from './firebase';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                EasyOrder
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
            <Link color="inherit" href="https://material-ui.com/">
            </Link>
        </Typography>
    );
}

const styles = {
    '@global': {
        body: {
            backgroundColor: 'white',
        },
    },
    paper: {
        backgroundColor: 'white',
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    Grid: {
        alignItems: 'center',
    },
    avatar: {
        margin: 1,
        backgroundColor: 'white',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: 1,
    },
    submit: {
        margin: '5px 0px 15px',
        backgroundColor: '#1BE9B6',
        color: 'white',
        height: '50px',
        '&:hover': {
            background: "rgb(19, 179, 139)"
        }
    },
    submit2: {
        margin: '5px 0px 5px',
        backgroundColor: '#3b5998',
        color: 'white',
        height: '50px',
        '&:hover': {
            background: "#2f55a4",
        },
    },
    signin: {
        color: '#aaaaaa'
    }

};

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    
}));

class LoginPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            email: "",
            password: "",
            errorTextEmail: "",
            errorTextPassword: "",
            auth: "",
            validation: "",
            message: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);

    }

    handleRefresh = () => {
        this.props.history.push("/");
    }

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        return token && token.length > 10;
    }

    handleChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value,
            validation: "",
            open: false
        });
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    submitForm = (event) => {
        event.preventDefault();
        if (this.state.email != "") {
            this.setState({ errorTextEmail: '', error: false })
        } else {
            this.setState({ errorTextEmail: 'Debe indicar el Email', error: true })
        }

        if (this.state.password != "") {
            this.setState({ errorTextPassword: '', error: false })
        } else {
            this.setState({ errorTextPassword: 'Debe indicar el Password', error: true })

        }

        const app = this;

        firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch(function (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            app.setState({
                validation: error.message,
                open: true,
                message: error.message
            });
        });


        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                console.log(user);
                firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
                    localStorage.setItem('token', idToken);
                    localStorage.setItem('userId', user.uid);
                    app.setState({});
                }).catch(function (error) {
                    // Handle error
                });
            } else {
                // No user is signed in.
            }

        });

    }

    render() {
        const classes = this.useStyles1;
        if (!localStorage.getItem("token")) {
            firebase.auth().signOut();
        }
        const isAlreadyAuthenticated = this.isAuthenticated();
        return (<div style={{marginTop:"150px"}}>
            {isAlreadyAuthenticated ? <Redirect to={{ pathname: '/recicla' }} /> : (
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Snackbar
                        style={{ backgroundColor: "#f44336", color: 'coral' }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        variant="success"
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    >
                        <SnackbarContent style={{
                            backgroundColor: '#f44336',
                        }}
                            message={<span id="client-snackbar">{this.state.message}</span>}
                        />
                    </Snackbar>

                    <div className={this.props.classes.paper}>
                        <form className={this.props.classes.form} noValidate onSubmit={this.submitForm.bind(this)}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email de recuperación"
                                name="email"
                                autoComplete="email"
                                value={this.state.email}
                                autoFocus
                                onChange={this.handleChange}
                                error={this.state.errorTextEmail.length === 0 ? false : true}
                                helperText={this.state.errorTextEmail}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                color="primary"
                                className={this.props.classes.submit}
                            >
                                Recuperar Contraseña
                    </Button>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Link href="forgot-pass" variant="body2" align="center">
                                Olvidaste tu Contraseña?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="register" variant="body2">
                                {"No tienes una cuenta UberGreen? Registrate"}
                            </Link>
                        </Grid>
                    </Grid>
                        </form>
                    </div>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </Container>
            )

            }</div>);

    }
}

export default withStyles(styles)(LoginPage);