import React, { Component } from 'react';
import firebase from './firebase';
import Firestore from '@firebase/firestore'
import SweetAlert from 'sweetalert2-react';


//components
import FormDirections from './FormDirection';
import FormMaterials from './FormMaterials';
import FormDetailsOrder from './FormDetailsOrder';
import Confirmado from './Confirmado';
import Stp from './Stp';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps"
import {MenuItem} from "@material-ui/core";

const google = window.google = window.google ? window.google : {}

export default class StepperForm extends Component{
    constructor(props){
        super(props)

        
        this.state = {
            tipo: "retiro",
            direction: "",
            medida:"",
            cantidad:"",
            material:"",
            valor:"",
            detalleRecicle:[],
            reciclerCenter:"test, alrededores cerro la virgen talca",
            rows:[],
            productos:[],
            step: 0,
            busqueda:"",
            selectedCenter: "",
            latitud: "",
            longitud: "",
            distancia: ""
        }
    }


//next step
  nextStep = () => {
    const { step } = this.state;
    if(step<=2){
        this.setState({
            step : step + 1
        })
    }

}

//reset
resetForm = () => {
    
    localStorage.removeItem('userOrder');
    localStorage.removeItem('detalleRecicle');
    localStorage.removeItem('totalDetalle');
    localStorage.removeItem('finalOrder');
    localStorage.removeItem('latlong ');
    
    this.setState({
        step : 0,
        direction: "",
        medida:"",
        cantidad:"",
        material:"",
        categoria:"",
        centro:"",
        tipo: "retiro",
        valor:"",
        reciclerCenter:"test, alrededores cerro la virgen talca",
        detalleRecicle:[],
        rows:[],
        busqueda:"",
        selectedCenter:"",
        latitud: "",
        longitud: "",
        distancia: ""
    });

}

//go back to prev step
prevStep = () => {
    const { step } = this.state;
    this.setState({
        step : step - 1
    })
}

createData(material, medida, cantidad, valor, centro, addressCenter,distancia,idCentro) {
    return { material, medida, cantidad, valor, centro, addressCenter,distancia,idCentro};
  }

//handle fields change
handleChange = input => e => {
    console.log(e.target.value);
    this.setState({[input]: e.target.value})
    const { tipo, direction, material, medida, cantidad,centro, detalleRecicle,selectedCenter,latitud,longitud } = this.state;
    const values = { tipo, direction, material, medida, cantidad,centro, detalleRecicle,selectedCenter,latitud,longitud }
    localStorage.setItem('userOrder', JSON.stringify(values));    
    
}

handleChangeDirection = (value,busqueda)=> {
    this.setState({direction: value, busqueda})

    const { tipo, direction, material, medida, cantidad, centro,detalleRecicle,selectedCenter,latitud,longitud } = this.state;
    const values = { tipo, direction, material, medida, cantidad,centro, detalleRecicle,selectedCenter,latitud,longitud }
    localStorage.setItem('userOrder', JSON.stringify(values));    
    
}

handleChangeMaterial = input => e => {


    this.listarCentros(e.target.value).then(items => {
        items.data.forEach((element, i) => {
            console.log(items.data[i]);
                var c = parseInt(items.data[i].profitPerUnit);

            if(c<0){

                alert('El producto seleccionado '+items.data[i].productName+', tiene un costo de tratamiento. Favor considerar que usted debe pagar por el reciclaje de este material.');
            }

        });

    }).catch(err => {
        console.log(err);
    })



    this.setState({[input]: e.target.value, valor: e.currentTarget.id, medida: e.currentTarget.title})
    //console.log(e.currentTarget.title);
    const { tipo, direction, material, medida, cantidad, centro,detalleRecicle,selectedCenter } = this.state;
    const values = { tipo, direction, material, medida, centro,cantidad, detalleRecicle,selectedCenter }

    localStorage.setItem('userOrder', JSON.stringify(values));
    
}

handleChangeCategories = input => async e => {
   // console.log(e.target.value);
    let productos = [];
    const prodRef = firebase.firestore().collection('Products');





    prodRef.where("categoryName","==",e.target.value).get().then((snap)=>{
        snap.forEach(function(doc) {
            //console.log(doc.data());
            //firebase.firestore().collection('ProductCategories').doc(doc.data().name);
            firebase.firestore().collection('Products').doc(doc.data().name +' ('+ doc.data().umName+')');
            productos.push(doc.data());
        })
        this.setState({ productos,  [input]: e.target.value});
    }).catch(function(error){
        console.log("Error getting documents");
    });
    
}


listarCentros = async (material) => {
    return new Promise(async (fulfill, reject) => {
        const db = firebase.firestore();
        const ref = db.collection('ReciclerCenters');
        try {
            const result = await ref.get();
            var data = [];
            const datos = [];

            const aux = await result.docs.map(async p => {
                const refDos = await db.collection('ReciclerCenters').doc(p.data().id)
                .collection('products').where("name","==",material).get();
                //return refDos.docs.map(dos => { return dos.data(); });
                return refDos.docs.map(dos => { 
                    return {
                        "address" : p.data().address,
                        "id" : p.data().id,
                        "location" : p.data().location,
                        "lat" : p.data().location._lat,
                        "lng" : p.data().location._long,
                        "name" : p.data().name,
                        "categoryID" : dos.data().categoryID,
                        "categoryName" : dos.data().categoryName,
                        "code" : dos.data().code,
                        "id_producto" : dos.data().id,
                        "productName" : dos.data().name,
                        "profitPerUnit" : dos.data().profitPerUnit,
                        "umID" : dos.data().umID,
                        "umName" : dos.data().umName
                    }
                });
            });

            const result2 = await Promise.all(aux);
            result2.forEach(element => {
               data = data.concat(element); 
            });

            if(data.length < 1){
                alert("Por ahora no tenemos ningún centro que reciba este material");
            }

            fulfill({
                data: data,
            })
        } catch (error) {
            reject(error)
        }
    })
}

calcDistance = (fromLat, fromLng, toLat, toLng) =>{
    return google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(fromLat, fromLng), new google.maps.LatLng(toLat, toLng));
 }


//handlAdd material
handleAdd = () => {
    const { tipo, direction, material, medida, cantidad,centro, valor, detalleRecicle, selectedCenter,latitud,longitud } = this.state;
    const values = { tipo, direction, material, medida, cantidad,centro, valor,selectedCenter,latitud,longitud }

    if(values.material==""){
        alert("Debe completar los campos para agregar el producto");    
    return;
    }
    if(values.cantidad==""){
        alert("Debe completar los campos para agregar el producto");    
     return;   
    }


    var latlng = JSON.parse(localStorage.getItem('latlong'));
    this.listarCentros(material).then(items => {
    
        items.data.forEach((element, i) => {


            if(this.state.tipo=='retiro'){
                if(items.data[i].name=='Reciclean Spa '){
                    var distancia = this.calcDistance(items.data[i].lat,items.data[i].lng,latlng.lat,latlng.lng).toFixed(2);
                    this.state.rows.push(this.createData(material, items.data[i].umName, cantidad, items.data[i].profitPerUnit, items.data[i].name, items.data[i].address,distancia,items.data[0].id));
                    localStorage.setItem('userOrder', JSON.stringify(values));
                    this.setState({
                        detalleRecicle:this.state.rows,
                    });
                }
                if(detalleRecicle==""){
                    localStorage.setItem('detalleRecicle', JSON.stringify(this.state.rows));
                }else{
                    localStorage.setItem('detalleRecicle', JSON.stringify(detalleRecicle));

                }


                this.setState({
                    medida:"",
                    cantidad:"",
                    material:"",
                    categoria:"",
                    centro :""
                })


            }
            if(this.state.tipo=='entrega'){
                var distancia = this.calcDistance(items.data[i].lat,items.data[i].lng,latlng.lat,latlng.lng).toFixed(2);
                this.state.rows.push(this.createData(material, items.data[i].umName, cantidad, items.data[i].profitPerUnit, items.data[i].name, items.data[i].address,distancia,items.data[0].id));
                localStorage.setItem('userOrder', JSON.stringify(values));
                this.setState({
                    detalleRecicle:this.state.rows,
                });
                if(detalleRecicle==""){
                    localStorage.setItem('detalleRecicle', JSON.stringify(this.state.rows));
                }else{
                    localStorage.setItem('detalleRecicle', JSON.stringify(detalleRecicle));

                }


                this.setState({
                    medida:"",
                    cantidad:"",
                    material:"",
                    categoria:"",
                    centro :""
                })
            }

           /* var detalleRecicle_aux = [];
            detalleRecicle_aux = localStorage.getItem("detalleRecicle");

            if(detalleRecicle_aux==""){
                localStorage.setItem('detalleRecicle', JSON.stringify(this.state.rows));
            }else{
                detalleRecicle_aux = localStorage.getItem("detalleRecicle");
                detalleRecicle_aux = JSON.stringify(this.state.rows);

                localStorage.setItem('detalleRecicle', detalleRecicle_aux);
            }*/


        });
        
    }).catch(err => {
        console.log(err);
    })
    
    console.log(detalleRecicle.length);
}

guardarOrden = ()=>{
    var tipo = (this.state.tipo=="retiro")? true : false;
    
    var totalDetalle = JSON.parse(localStorage.getItem("totalDetalle"));
    var userOrder = JSON.parse(localStorage.getItem("userOrder"));
    var detalleRecicle = JSON.parse(localStorage.getItem("detalleRecicle"));
    var finalOrder = JSON.parse(localStorage.getItem("finalOrder"));
    var reciclerCenter = firebase.firestore().collection("ReciclerCenters");
    var userId = localStorage.getItem("userId");
    var count = 0;
    var count_register = 0;

    if(this.state.tipo=="entrega"){
        var aux = detalleRecicle.filter(d => d.centro === finalOrder[0].centro)
        detalleRecicle = aux;
        detalleRecicle.map(item => {
            var aux = detalleRecicle.filter(d => d.idCentro === finalOrder[count_register].idCentro)
            count_register++;
        });
        detalleRecicle = aux;
    }
    if(this.state.tipo=="retiro"){

        detalleRecicle.map(item => {
            count_register++;
        });



    }


var data = [];





    detalleRecicle.map(item => {

    reciclerCenter.doc(item.idCentro).collection('products').where("name","==",item.material)
   .get()
    .then(snapshot => {
        if (snapshot.empty) {
            console.log('No matching documents.');
            return;
        }

        snapshot.forEach(doc => {
          console.log(doc.id, '=>', doc.data());



             data = {
                address: this.state.direction,
                delivery: tipo,
                userID: userId,
                reciclerID: finalOrder[0].idCentro,
                orderId: totalDetalle.orderId,
                ganancia_aprox: totalDetalle.aprox,
                total: totalDetalle.total,
                centro: finalOrder[0].centro,
                direccion: finalOrder[0].addressCenter,
                cantidad: finalOrder[count].cantidad,
                material: finalOrder[count].material,
                medida: finalOrder[count].medida,
                products:
                    [
                        {productID: doc.id, quantity: finalOrder[count].cantidad},
                    ]};



  fetch("https://us-central1-ubergreen-c4174.cloudfunctions.net/initOrder", {
                body: JSON.stringify(data),
                method: 'POST',
                crossDomain: true,
                headers:{
                    "Content-Type": "application/json"
                }
            }).then(async res => {


                    const value = await res.json();
                    //console.log(value);
                    const code = value.code;
                    const uid = localStorage.getItem("userId");
                    const detailsProductos=[];


                    if(count_register==count){

                        this.nextStep();
                    }

                }).catch((error)=>{
                console.log("Error agregando ordenes");
            });

            data ='';
            count++;
        //return;
        });
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });

    });

    //return;
 /*   const data = {
        address: this.state.direction,
        delivery: tipo,
        userID: userId,
        reciclerID: finalOrder[0].idCentro,
        orderId: totalDetalle.orderId,
        products:
            [
                {productID: "0xgt0ivXfcZuaD7U3p6j", quantity: finalOrder[0].cantidad},
        ]
        
    };                       

    console.log(data);
    //return;
   
    fetch("https://us-central1-ubergreen-c4174.cloudfunctions.net/initOrder", {
        body: JSON.stringify(data),
        method: 'POST',
        crossDomain: true,
        headers:{
            "Content-Type": "application/json"
        }               
    })
    .then(async res => {
    const value = await res.json();
    //console.log(value);
    const code = value.code;  
    const uid = localStorage.getItem("userId");
    const detailsProductos=[];
    this.nextStep();
    //this.resetForm();

    }).catch((error)=>{
        console.log("Error agregando ordenes");
    })*/
}

componentWillMount(){
    const userOrderStorage = JSON.parse(localStorage.getItem('userOrder'));
    const detalleRecicleStorage= JSON.parse(localStorage.getItem('detalleRecicle'));
    const categoryRef = firebase.firestore().collection('ProductCategories');
    const prodRef = firebase.firestore().collection('ReciclerCenters');
    const ordenesRef = firebase.firestore().collection('Orders');
    const productos = [];
    const categories = [];
    const categoriesOrder = [];
    const ordenes = [];
    const latlng = JSON.parse(localStorage.getItem('latlong'));

    //.where("manager","==",uid)
    //.where("code","==",code);

    /*Comentado para probar con el cliente
    prodRef.get().then((snapshot)=>{
      snapshot.forEach(function(doc) {
        prodRef.doc(doc.id).collection('products').get().then((snap)=>{
                snap.forEach(function(doc) {
                    console.log(doc.data());
                    productos.push(doc.data());
                })
            })
        });
        
        this.setState({ productos });
    }).catch(function(error){
        console.log("Error getting documents");
    });
*/
    prodRef.doc('BAo6VfQiWyPOZ6HoH4QA').collection('products').get().then((snap)=>{
        snap.forEach(function(doc) {
            //console.log(doc.data());
            productos.push(doc.data());
        })
        this.setState({ productos });
    }).catch(function(error){
        console.log("Error getting documents");
    });
    
    categoryRef.get().then((snap)=>{
        snap.forEach(function(doc) {
            //console.log(doc.data());
            categoriesOrder.push(doc.data());
        })
        
        const categories = categoriesOrder.sort(function(a, b) {
            return a.name<b.name ? -1 : a.name>b.name ? 1 : 0;
         });

        this.setState({ categories });
    }).catch(function(error){
        console.log("Error getting documents");
    });
    
    
    if(userOrderStorage){
        this.setState({
            direction: userOrderStorage.direction,
            tipo: userOrderStorage.tipo,
        });
    }
    if(detalleRecicleStorage){
        detalleRecicleStorage.map(item=>{
            this.state.rows.push(this.createData(item.material, item.medida, item.cantidad,item.valor, item.centro, item.addressCenter,item.distancia, item.idCentro));
        });
        this.setState({
            detalleRecicle:this.state.rows,
        });
    }


    if(this.props.ruta.state!=undefined){
        if(this.props.ruta.state.id==2){
            this.setState({step: 2})
        }
    }
}

makeId() { 
    var text = ""; 
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; 
    for (var i = 0; i < 8; i++) text += possible.charAt(Math.floor(Math.random() * possible.length)); 
    return "#"+text; } 
    
render() {
    
    const { step } = this.state;
    const { tipo, direction, material,categoria, medida, cantidad,centro, detalleRecicle, productos, categories, reciclerCenter, busqueda,selectedCenter,latitud,longitud } = this.state;
    const values = { tipo, direction, material,categoria, medida, cantidad,centro, detalleRecicle, productos, categories, reciclerCenter, busqueda,selectedCenter,latitud,longitud }
    
    
    const userOrderStorage = JSON.parse(localStorage.getItem('userOrder'));
    const detalleRecicleStorage= JSON.parse(localStorage.getItem('detalleRecicle'));
    
    {
        switch(step){
            case 0:
                return (
                    <React.Fragment>
                        <Stp activeSteps={step} values={values}/>
                    <FormDirections oStorage = {userOrderStorage} resetForm={this.resetForm} nextStep ={this.nextStep} handleChange={this.handleChange} handleChangeDirection={this.handleChangeDirection} values={values}/>
                    </React.Fragment>
                )
            case 1:
                return (
                    <React.Fragment>
                    <Stp activeSteps={step} values={values}/>
                    <FormMaterials prevStep ={this.prevStep} resetForm={this.resetForm} handleAdd={this.handleAdd} nextStep ={this.nextStep} handleChange={this.handleChange} handleChangeMaterial={this.handleChangeMaterial} handleChangeCategories={this.handleChangeCategories} values={values} oStorage = {userOrderStorage} selectedCenter={selectedCenter}/>
                    </React.Fragment>
                )
            case 2:
                return (
                    <React.Fragment>
                    <Stp activeSteps={step} values={values}/>
                    <FormDetailsOrder makeId={this.makeId} prevStep ={this.prevStep} nextStep ={this.nextStep} handleChange={this.handleChange} guardarOrden={this.guardarOrden} values={values}/>
                    </React.Fragment>
                    
                )
            case 3:
                return (
                    <React.Fragment>
                    <Stp activeSteps={step} values={values}/>
                    <Confirmado prevStep ={this.prevStep} nextStep ={this.nextStep} handleChange={this.handleChange} values={values} resetForm={this.resetForm}/>
                    </React.Fragment>
                )    
        }
    }
  }
}