import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DownloadProductos from '../DownloadProductos';



const columns = [
  { id: 'code', label: 'Codigo ID', minWidth: 50 },
  { id: 'name', label: 'Nombre', minWidth: 100 },
  { id: 'categoryName', label: 'Categoria', minWidth: 100 },
  {
    id: 'profitPerUnit',
    label: 'Valor por Unidad',
    minWidth: 120,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'umName',
    label: 'Unidad de Medida',
    minWidth: 120,
    align: 'right',
    format: value => value.toFixed(2),
  },
];

var formatNumber = {
  separador: ".", // separador para los miles
  sepDecimal: ',', // separador para los decimales
  formatear: function (num) {
      num += '';
      var splitStr = num.split('.');
      var splitLeft = splitStr[0];
      var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
      var regx = /(\d+)(\d{3})/;
      while (regx.test(splitLeft)) {
          splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
      }
      return this.simbol + splitLeft + splitRight;
  },
  new: function (num, simbol) {
      this.simbol = simbol || '';
      return this.formatear(num);
  }
}

function createData(code, name, categoryName, profitPerUnit, umName) {
  return { code, name, categoryName, profitPerUnit, umName};
}

const rows2 = [
  createData('India', 'IN', 1324171354, 3287263, 3287263),
  createData('China', 'CN', 1403500365, 9596961, 3287263),
  createData('Italy', 'IT', 60483973, 301340, 3287263),
  createData('United States', 'US', 327167434, 9833520, 3287263),
  createData('Canada', 'CA', 37602103, 9984670, 3287263),
  createData('Australia', 'AU', 25475400, 7692024, 3287263),
  createData('Germany', 'DE', 83019200, 357578, 3287263),
  createData('Ireland', 'IE', 4857000, 70273, 3287263),
  createData('Mexico', 'MX', 126577691, 1972550, 3287263),
  createData('Japan', 'JP', 126317000, 377973, 3287263),
  createData('France', 'FR', 67022000, 640679, 3287263),
  createData('United Kingdom', 'GB', 67545757, 242495, 3287263),
  createData('Russia', 'RU', 146793744, 17098246, 3287263),
  createData('Nigeria', 'NG', 200962417, 923768, 3287263),
  createData('Brazil', 'BR', 210147125, 8515767, 3287263),
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: 'auto',
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [open, setOpen] = React.useState(false);

  
function handleClickOpen() {
  setOpen(true);
}

function handleClose() {
  setOpen(false);
}

function handleCloseSubmit() {
  handleSubmit();
  setOpen(false);
}



  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const { values } = props; 
  const { handleChange, handleSubmit, handleChangeSelect, handleChangeProduct } = props;
  const rows = [];


  values.products.map(item =>{
  return rows.push(createData(item.code, item.name, item.categoryName, formatNumber.new(parseInt(item.profitPerUnit), "$"),item.umName));
  });

  //console.log(rows);

  return (
    <React.Fragment>
      <DownloadProductos/>
    <div className="row">
      <div className="col col-md-12 text-right" style={{paddingRight:'10px', paddingBottom:'20px'}}>
            <button onClick={handleClickOpen} type="submit" className="btn btn-primary mb-2">Agregar Producto</button>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Agregar Producto</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText>
        <FormControl>
        <InputLabel htmlFor="age-helper">Productos</InputLabel>
        <Select
          name={"productName"}
          value={values.productID}
          onChange={handleChangeProduct("productID")}
          style={{width:"520px"}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {values.cboProducts.map(item=>(
            <MenuItem value={item.id}>{item.name}</MenuItem>   
          ))}
        </Select>
        <FormHelperText>Seleccione el nombre del producto</FormHelperText>
      </FormControl>
        <FormControl>
        <InputLabel htmlFor="age-helper">Categoria</InputLabel>
        <Select
          name={"categoryName"}
          value={values.categoryID}
          onChange={handleChangeSelect("categoryID")}
          style={{width:"320px"}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {values.categories.map(item=>(
            <MenuItem value={item.id}>{item.name}</MenuItem>   
          ))}
        </Select>
        <FormHelperText>Seleccione la categoria a la que pertenece el producto</FormHelperText>
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="age-helper">Unidad de Medida</InputLabel>
        <Select
          name={"umName"}
          value={values.umID}
          onChange={handleChangeSelect("umID")}
          style={{width:"320px"}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {values.measureUnits.map(item=>(
            <MenuItem value={item.id}>{item.name}</MenuItem>   
          ))}
        </Select>
        <FormHelperText>Seleccione la unidad de medida del productor</FormHelperText>
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="age-helper">Centro de Acópio</InputLabel>
        <Select
          value={values.center}
          onChange={handleChange("center")}
          style={{width:"320px"}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {values.reciclerCenter.map(item=>(
            <MenuItem id={item.id} value={item.id}>{item.name}</MenuItem>   
          ))}
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
        <FormHelperText>Seleccione la unidad de medida del productor</FormHelperText>
      </FormControl>
          
          <TextField
            margin="dense"
            id="profitPerUnit"
            name="profitPerUnit"
            label="Valor"
            type="text"
            value={values.profitPerUnit}
            onChange={handleChange("profitPerUnit")}
            fullWidth
            style={{width:"320px"}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseSubmit} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </React.Fragment>
  );
}