import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PhoneForwarded from '@material-ui/icons/PhoneForwarded';
import FilterListIcon from '@material-ui/icons/FilterList';
import firebase from '../firebase';
import useForceUpdate from 'use-force-update';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Download from '../DownloadProductos';

//function createData(name, calories, fat, carbs, protein) {
// return { name, calories, fat, carbs, protein };
//}


function createData(code, center, name, category, value, unity, centerId) {
  return { code, center, name, category, value, unity, centerId };
}


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const columns = [
  { id: 'code', label: 'Codigo ID', minWidth: 50 },
  {
    id: 'center',
    label: 'Centro',
    minWidth: 200,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'name',
    label: 'Nombre',
    minWidth: 200,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'category',
    label: 'Categoria',
    minWidth: 200,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'value',
    label: 'Valor por Unidad',
    minWidth: 90,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'unity',
    label: 'Unidad de Medida',
    minWidth: 90,
    align: 'left',
    format: value => value.toFixed(2),
  },
];

const headCells = [
  { id: 'codigo', numeric: false, disablePadding: true, label: 'Codigo ID' },
  { id: 'center', numeric: false, disablePadding: false, label: 'Centro' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
  { id: 'category', numeric: false, disablePadding: false, label: 'Categoría' },
  { id: 'value', numeric: false, disablePadding: false, label: 'Valor por Unidad' },
  { id: 'unity', numeric: false, disablePadding: false, label: 'Unidad de Medida' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {headCells.map(headCell => (
              <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
      theme.palette.type === 'light'
          ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
          : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;


  const Botones = () => {
    return (
        <React.Fragment>
          <div className="row">
            <div className="col-md-4">
              <Tooltip title="Delete" onClick={props.handleDelete}>
                <IconButton aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </React.Fragment>
    );
  }

  return (
      <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
              <Typography color="inherit" variant="subtitle1">
                {numSelected} selected
              </Typography>
          ) : (
              <Typography variant="h6" id="tableTitle">
                Productos
              </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 ? (
              <Botones />
          ) : (
              <React.Fragment>
                <Download productos={props.productos} />
                {/*<Tooltip title="Filter list">
              <IconButton aria-label="filter list">
                <FilterListIcon />
              </IconButton>
        </Tooltip>*/}
              </React.Fragment>
          )}
        </div>
      </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const { productos } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [full, setFull] = React.useState(false);
  //const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [display, setDisplay] = React.useState("block");
  const [editar, setEditar] = React.useState("none");

  const forceUpdate = useForceUpdate();
  const rows = [];
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }

  function handleChangeFull(event) {
    let cant;
    if (event.target.checked) {
      if (rows.length < 2000) {
        cant = rows.length;
      } else {
        cant = 2000;
      }
      setRowsPerPage(+cant);
      setPage(0);
    } else {
      setRowsPerPage(+5);
      setPage(0);
    }
    setFull(event.target.checked);
  }

  function handleDelete() {
    const refProducts = firebase.firestore().collection("ReciclerCenters");
    selected.map(item => {
      productos.filter(rows => {
        if(rows.code == item){
          //valores.push({"code" : rows.code});
          refProducts.doc(rows.centerId).collection("products").doc(rows.id).delete().then(()=>{
            console.log("Eliminado el producto: "+rows.id);
            //  props.actualizar();        
          }).catch(e => {
            console.log(e);
          });
        }
      });
      setSelected([]);
      props.actualizar();

    })
    //console.log(valores);
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClickOpen2() {
    setOpen2(true);
  }

  function handleClose() {
    setOpen(false);
  }
  function handleClose2() {
    setOpen2(false);
  }

  function handleCloseSubmit() {
    handleSubmit();
    setOpen(false);
  }

  function handleCloseSubmit2() {
    handleEditar();
    setOpen2(false);
  }



  function handleStatus() {
    console.log(selected);
    const refOrders = firebase.firestore().collection("Orders");
    selected.map(item => {
      refOrders.doc(item).update({ status: 'Contactado' }).then((res) => {
        console.log("se actualizó el registro");
        setSelected([]);
      }).catch(err => {
        console.log("no se pudo actualizar el registro");
      });
      props.actualizar();
    })
  }

  props.productos.map(item => {
    var tipo = (item.delivery) ? 'Retiro' : 'Entrega';
    var status = (item.status) ? item.status : "Pendiente";
    rows.push(createData(item.code, item.center, item.name, item.categoryName, item.profitPerUnit, item.umName, item.centerId));
  });

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const { values } = props;

  const { handleSetChange, handleChange, handleEditar, handleSubmit, handleChangeSelect, handleChangeProduct } = props;

  async function handleEdit(selected,valor,center){
    console.log(valor);
    // console.log(center);
    const refProducts = firebase.firestore().collection("ReciclerCenters").doc(center).collection('products').where("code","==",valor);
    let params;
    let idProducto;
    refProducts.get().then((snapshot) =>{
      snapshot.docs.forEach(async element => {
        console.log(element.data());
        const productos = await firebase.firestore().collection("Products").where("name","==",element.data().name).get();
        productos.forEach(doc => {
          idProducto = doc.id;
          params = {
            umName: element.data().umName,
            categoryName: element.data().categoryName,
            name: element.data().name,
            idProduct : element.data().id,
            productID: idProducto,
            categoryID: element.data().categoryID,
            umID: element.data().umID,
            center: center,
            profitPerUnit: element.data().profitPerUnit
          }
        });
        handleSetChange(params);
      });
    }).catch(error => error);

    handleClickOpen2();
  }

  var formatNumber = {
    separador: ".", // separador para los miles
    sepDecimal: ',', // separador para los decimales
    formatear: function (num) {
      num += '';
      var splitStr = num.split('.');
      var splitLeft = splitStr[0];
      var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
      var regx = /(\d+)(\d{3})/;
      while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
      }
      return this.simbol + splitLeft + splitRight;
    },
    new: function (num, simbol) {
      this.simbol = simbol || '';
      return this.formatear(num);
    }
  }

  return (
      <div>
        <React.Fragment>
          <div className="row">
            <div className="col col-md-12 text-right" style={{paddingRight:'10px', paddingBottom:'20px'}}>
              <button onClick={handleClickOpen} type="submit" className="btn btn-primary mb-2">Agregar Producto</button>
            </div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Agregar Producto</DialogTitle>
              <DialogContent>
                <DialogContentText>

                </DialogContentText>
                <FormControl>
                  <InputLabel htmlFor="age-helper">Productos</InputLabel>
                  <Select
                      name={"productName"}
                      value={values.productID}
                      onChange={handleChangeProduct("productID")}
                      style={{width:"520px"}}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {values.cboProducts.map(item=>(
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Seleccione el nombre del producto</FormHelperText>
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="age-helper">Categoria</InputLabel>
                  <Select
                      name={"categoryName"}
                      value={values.categoryID}
                      onChange={handleChangeSelect("categoryID")}
                      style={{width:"320px"}}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {values.categories.map(item=>(
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Seleccione la categoria a la que pertenece el producto</FormHelperText>
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="age-helper">Unidad de Medida</InputLabel>
                  <Select
                      name={"umName"}
                      value={values.umID}
                      onChange={handleChangeSelect("umID")}
                      style={{width:"320px"}}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {values.measureUnits.map(item=>(
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Seleccione la unidad de medida del producto</FormHelperText>
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="age-helper">Centro de Acópio</InputLabel>
                  <Select
                      value={values.center}
                      onChange={handleChange("center")}
                      style={{width:"320px"}}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {values.reciclerCenter.map(item=>(
                        <MenuItem id={item.id} value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Seleccione la unidad de medida del productor</FormHelperText>
                </FormControl>

                <TextField
                    margin="dense"
                    id="profitPerUnit"
                    name="profitPerUnit"
                    label="Valor"
                    type="text"
                    value={values.profitPerUnit}
                    onChange={handleChange("profitPerUnit")}
                    fullWidth
                    style={{width:"320px"}}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleCloseSubmit} color="primary">
                  Agregar
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </React.Fragment>
        <React.Fragment>
          <div className={classes.root} style={{display:display}}>
            <Paper className={classes.paper}>
              <EnhancedTableToolbar numSelected={selected.length} handleEdit={handleEdit} handleDelete={handleDelete} handleStatus={handleStatus} productos={props.productos} />
              <div className={classes.tableWrapper}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                  />
                  <TableBody>
                    {stableSort(rows, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.code);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                              <TableRow
                                  hover
                                  //onClick={event => handleClick(event, row.code)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.code}
                                  selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                      onClick={event => handleClick(event, row.code)}
                                      checked={isItemSelected}
                                      inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </TableCell>
                                <TableCell component="th" id={labelId} scope="row" padding="none">
                                  {row.code}
                                </TableCell>
                                <TableCell align="left">{row.center}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.category}</TableCell>
                                <TableCell align="left">{formatNumber.new(parseInt(row.value), "$")}</TableCell>
                                <TableCell align="left">{row.unity}</TableCell>
                                <TableCell align="left">
                                  <IconButton aria-label="Edit" onClick={event => handleEdit(event, row.code, row.centerId)}>
                                    <EditIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                          );
                        })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
            <FormControlLabel
                control={<Switch checked={full} onChange={handleChangeFull} />}
                label="2000 rows"
            />
          </div>
          <div>
            <div className="row">
              <Dialog open={open2} onClose={handleClose2} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Editar Producto</DialogTitle>
                <DialogContent>
                  <DialogContentText>

                  </DialogContentText>
                  <FormControl>
                    <InputLabel htmlFor="age-helper">Productos</InputLabel>
                    <Select
                        name={"productName"}
                        value={values.productID}
                        onChange={handleChangeProduct("productID")}
                        style={{width:"520px"}}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {values.cboProducts.map(item=>(
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Seleccione el nombre del producto</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="age-helper">Categoria</InputLabel>
                    <Select
                        name={"categoryName"}
                        value={values.categoryID}
                        onChange={handleChangeSelect("categoryID")}
                        style={{width:"320px"}}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {values.categories.map(item=>(
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Seleccione la categoria a la que pertenece el producto</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="age-helper">Unidad de Medida</InputLabel>
                    <Select
                        name={"umName"}
                        value={values.umID}
                        onChange={handleChangeSelect("umID")}
                        style={{width:"320px"}}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {values.measureUnits.map(item=>(
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Seleccione la unidad de medida del producto</FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel htmlFor="age-helper">Centro de Acópio</InputLabel>
                    <Select
                        value={values.center}
                        onChange={handleChange("center")}
                        style={{width:"320px"}}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {values.reciclerCenter.map(item=>(
                          <MenuItem id={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Seleccione la unidad de medida del productor</FormHelperText>
                  </FormControl>

                  <TextField
                      margin="dense"
                      id="profitPerUnit"
                      name="profitPerUnit"
                      label="Valor"
                      type="text"
                      value={values.profitPerUnit}
                      onChange={handleChange("profitPerUnit")}
                      fullWidth
                      style={{width:"320px"}}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose2} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleCloseSubmit2} color="primary">
                    Editar
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </React.Fragment>
      </div>
  );
}
