import React, { Component } from 'react';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { Button, MenuItem, Select, InputLabel, FilledInput } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';

//components
import Selection from './Selection';

const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
     },
});

const GreenRadio = withStyles({
    root: {
        color: "#333333",
        '&$checked': {
            color: "#1BE9B6",
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

//components

export default class FormMaterials extends Component {

    constructor(props) {
        super(props)

        this.state = {
            step: 1,
        }
    }

    render() {
        var formatNumber = {
            separador: ".", // separador para los miles
            sepDecimal: ',', // separador para los decimales
            formatear: function (num) {
                num += '';
                var splitStr = num.split('.');
                var splitLeft = splitStr[0];
                var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
                var regx = /(\d+)(\d{3})/;
                while (regx.test(splitLeft)) {
                    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
                }
                return this.simbol + splitLeft + splitRight;
            },
            new: function (num, simbol) {
                this.simbol = simbol || '';
                return this.formatear(num);
            }
        }

        const classes = makeStyles(theme => ({
            root: {
                display: 'flex',
            },
            formControl: {
                margin: theme.spacing(3),
            },
            group: {
                margin: theme.spacing(1, 0),
            },
            textField: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        }));

        const CssTextField = withStyles({
            root: {

            },
        })(Select);

        const { values, handleChange, prevStep, nextStep, handleAdd, makeId, resetForm, guardarOrden } = this.props;
        const user = localStorage.getItem("user");

        var td = JSON.parse(localStorage.getItem("totalDetalle"));
        var finalOrder = JSON.parse(localStorage.getItem("finalOrder"));
        var orderId = makeId();
        (td == null) ? orderId = orderId : orderId = td.orderId;

        var addressCenter = "";
        var centro = "";
        var ganancia = 0;
        var total = 0;
        var descuento = 3000;
        var aprox = 0;
        //values.detalleRecicle.map(item => {
        finalOrder.map(item => {
            ganancia = parseInt(item.cantidad * item.valor);
            total += ganancia;
            addressCenter = item.addressCenter;
            centro = item.centro;
        });

        (values.tipo == "retiro") ? aprox = total - descuento : aprox = total;

        localStorage.setItem("totalDetalle", JSON.stringify({
            total: total,
            aprox: aprox,
            orderId: orderId
        }));

        const username = localStorage.getItem('username');
        const email = localStorage.getItem('email');
        const phone = localStorage.getItem('phone');


        if(aprox<0){
            var show_info ="block";
        }


        if(aprox>0){
            var show_info ="none";
        }


     /*   const WebPay = require('webpay-nodejs');

        let wp = new WebPay({
            commerceCode: youCommerceCode,
            publicKey: youPublicKey, // .cert file
            privateKey: youPrivateKey, // .key file
            webpayKey: youWebpayKey, // .pem file
            env: WebPay.ENV.INTEGRACION
        });

        wp.initTransaction({
            buyOrder: buyOrden,
            sessionId: req.sessionId,
            returnURL: url + '/verificar',
            finalURL: url + '/comprobante',
            amount: amount
        }).then((data) => {
            res.redirect(data.url + '?token_ws=' + data.token);
        })*/

        return (<div>
            {//!(localStorage.getItem('userId')) ? (
                (2 == 1) ? (
                    <div>
                        <div style={{ backgroundColor: "pink", width: "400px", height: "100px", margin: "50px auto", paddingTop: "20px" }}>
                            <span>Para poder realizar tu solicitud es necesario <br />ingresar o registrarse en nuestra plataforma.</span>
                        </div>
                        <div className="container-fluid recicla" >
                            <div className="col-8 text-center ml-auto  mr-auto"><br />
                                <MyButton>
                                    <Link to={'/login'} style={{ color: "#ffffff" }}>
                                        INGRESA
                            </Link>
                                </MyButton>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div>
                            <div className="container-fluid">
                                <div className="row" style={{ backgroundColor: "#fff", paddingBottom: "30px" }}>
                                    <div className="col-4 ml-auto text-left">
                                        Retiro
                                        <GreenRadio
                                            checked={values.tipo === 'retiro'}
                                            onChange={handleChange('tipo')}
                                            value="retiro"
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'retiro' }}
                                        />

                                    </div>
                                    <div className="col-4 mr-auto text-right">
                                        Entrega
                                        <GreenRadio
                                            checked={values.tipo === 'entrega'}
                                            onChange={handleChange('tipo')}
                                            value="entrega"
                                            name="radio-button-demo"
                                            inputProps={{ 'aria-label': 'entrega' }}
                                        />

                                    </div>
                                </div>
                                <div className="container text-center">
                                    <div className="row">
                                        <div className="col-8 mx-auto ml-auto mr-auto text-left" style={{ backgroundColor: "#eeeeee" }}>
                                           <hr/>
                                            <p><span><b>{username}</b></span>
                                                <br />Dirección: {values.direction}
                                                <br />Email: {email}
                                                <br />Phone: {phone}
                                                <br />
                                                {(username)?(
                                                    //`${"Centro de reciclaje: " + centro +"-"+ addressCenter}`
                                                    "Centro de reciclaje: UberGreen"
                                                ):(
                                                    "Centro de reciclaje: UberGreen"
                                                )}
                                            </p>
                                        </div>

                                        <div className="col-8 mx-auto ml-auto mr-auto text-left" style={{ backgroundColor: "#eeeeee" }}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <hr/>

                                                    <p>
                                                        {finalOrder.map((item, i) => {
                                                            return (
                                                                <div key={i}>Cantidad: {item.cantidad} {item.medida} {item.material} - Ganancia {formatNumber.new((item.valor*item.cantidad), "$")}</div>
                                                            )
                                                        })}
                                                    </p>
                                                    <hr/>

                                                </div>

                                                <div className="col-6 text-right" style={{ verticalAlign:"center" }}>
                                                    <hr/>
                                                    <br/>

                                                    <h1 className="codigo_orden">{orderId.toUpperCase()}</h1>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-8 mx-auto ml-auto mr-auto text-left" style={{ backgroundColor: "#EE7576",display:show_info}}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <hr/>
                                                    <p  style={{ color: "#FFFFFF" }}>
                                                        <span><b> Información importante: </b> </span>
                                                        <br />Dado que el valor es negativo, usted deberá cancelar el monto indicado a través de transferencia bancaria previo al retiro, los datos para el deposito serán enviados vía correo electrónico.
                                                    </p>
                                                    <hr/>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-8 mx-auto ml-auto mr-auto text-left">
                                            <div className="row">
                                                <div className="col-12 text-right">
                                                    <hr/>
                                                    <span>Monto ganancia aprox.</span>
                                                </div>
                                                <div className="col-12 text-right">
                                                    <h3 style={{color:"#A5A5A5"}}>{formatNumber.new(parseInt(total), "$")}</h3>
                                                    <hr/>

                                                </div>
                                                {
                                                    (values.tipo === "retiro") ? (
                                                        <React.Fragment>
                                                            <div className="col-12 text-right">
                                                                <span>Costo retiro domicilio</span>
                                                            </div>
                                                            <div className="col-12 text-right">
                                                                <h3 style={{color:"red"}}>- {formatNumber.new(3000, "$")}</h3>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                            <div></div>
                                                        )}
                                                <div className="col-12 text-right">
                                                    <span>Total ganancia aprox.</span>
                                                </div>
                                                <div className="col-12 text-right">
                                                    <h3>{formatNumber.new(aprox, "$")}</h3>
                                                    <hr/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid recicla" >
                                <div className="row boton-recicla">
                                    <div className="col-8 text-right ml-auto  mr-auto"><br />
                                        <MyButton onClick={prevStep}>Atras</MyButton>&nbsp;&nbsp;
                                    {!(localStorage.getItem('userId')) ? (
                                            <MyButton>
                                                <Link to={'/confirm'} style={{ color: "#ffffff" }}>
                                                    Confirmar
                                                </Link>
                                            </MyButton>
                                        ) : (
                                                <MyButton onClick={guardarOrden}>Confirmar</MyButton>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}</div>
        )
    }
}


