import React, { Component } from 'react';
import { makeStyles, styled, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ThemeProvider } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import MapContainerPolyline from './MapContainerPolyline';
import Button from '@material-ui/core/Button';
import Geocode from "react-geocode";

const google = window.google = window.google ? window.google : {}
const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
     },
});

//components

export default class FormDirections extends Component {

    constructor(props) {
        super(props)
        this.direction = React.createRef()
        this.state = {
            step: 1,
            busqueda:"",
            show:true,
            direction:"",
            lat:"",
            lng:"",
            marca:false
        }
    
        this.handleBusqueda = this.handleBusqueda.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLatLong = this.handleChangeLatLong.bind(this);

    }

    handleChangeLatLong = (lat, long)=>{
        this.setState({lat: lat, long:long, marca: true});
    }

    handleChange = input => e => {
        this.setState({[input]: e.target.value})
    }

    consultarDireccion = async (direction,bermudaTriangle) => {
        
        return new Promise(async (fulfill, reject) => {
        try {
            const inside = await Geocode.fromAddress(direction);  
            const { lat, lng } = inside.results[0].geometry.location;
            this.handleChangeLatLong(lat,lng);
            var coordinate = new google.maps.LatLng(lat, lng); 
            if (window.google.maps.geometry.poly.containsLocation(coordinate, bermudaTriangle)) {
              //  console.log('inside the city');
                return fulfill({state: true});
            } else {
              //  console.log('outside the city');
                return reject(false)
            }
        } catch (error) {
            console.log(error);
        }
    })
    }

    handleBusqueda = ()=>{
        var valor = document.getElementById('standard-full-width-mio').value;
        var busqueda = document.getElementById('standard-full-width-mio').value;
        this.setState({busqueda})
        if (busqueda != "") {
            Geocode.setApiKey("AIzaSyCNFNOpf7RT_JbBBSkMfWU5CRpmwsr5lJY");
            Geocode.setLanguage("en");
            Geocode.setRegion("es");
            Geocode.enableDebug();
            const inside = Geocode.fromAddress(busqueda).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    localStorage.setItem("latlong",JSON.stringify({lat, lng}));
                    this.handleChangeLatLong(lat,lng);
                },
                error => {
                    console.error(error);
                }
            );
        }
        this.props.handleChangeDirection(valor,busqueda);
        
    }

    render() {
        const classes = makeStyles(theme => ({
            root: {
                display: 'flex',
                width: '980px'
            },
            formControl: {
                margin: theme.spacing(3),
            },
            group: {
                margin: theme.spacing(1, 0),
            },
            textField: {

            }
        }));

        const theme = createMuiTheme({
            overrides: {
                MuiTextField: {
                    root: {


                    }
                }
            }
        });

        const CssTextField = withStyles({
            root: {
                '& label.Mui-focused': {
                    color: 'white',
                },
                '& .MuiInput-underline-hover': {
                    borderBottomColor: '#1BE9B6',
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: '#1BE9B6',
                },
                '& .MuiInput-underline:before': {
                    borderBottomColor: '#1BE9B6',
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#1BE9B6',
                    },
                    '&:hover fieldset': {
                        borderColor: '#1BE9B6',
                    },
                    '&:hover': {
                        borderButtomColor: '#1BE9B6',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#1BE9B6',
                    },
                },
            },
        })(TextField);

        const { values, handleChange,handleLatLong, nextStep, resetForm, oStorage } = this.props;

       // console.log(values);
        const GreenRadio = withStyles({
            root: {
                color: "#333333",
                '&$checked': {
                    color: "#1BE9B6",
                },
            },
            checked: {},
        })(props => <Radio color="default" {...props} />);

        var latlong = JSON.parse(localStorage.getItem("latlong"));
        return (

            <React.Fragment>
                <ThemeProvider theme={theme}>
                    <div className="row" style={{ backgroundColor: "#fff" }}>
                        <div className="col-4 ml-auto text-left">
                            Retiro
                            <GreenRadio
                                checked={values.tipo === 'retiro'}
                                onChange={handleChange('tipo')}
                                value="retiro"
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'retiro' }}
                            />

                        </div>
                        <div className="col-4 mr-auto text-right">
                            Entrega
                            <GreenRadio
                                checked={values.tipo === 'entrega'}
                                onChange={handleChange('tipo')}
                                value="entrega"
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'entrega' }}
                            />

                        </div>
                        <div className="col-8 mx-auto">
                            <TextField
                                id="standard-full-width-mio"
                                ref = { this.direction }
                                style={{ margin: 0, maxWidth: '930px', backgroundColor: "#eeeeee", marginTop: "30px" }}
                                placeholder="Dirección"
                                fullWidth
                                multiline
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className="col-8 mx-auto" style={{paddingTop: "10px"}}>
                            <MyButton onClick={this.handleBusqueda}>buscar</MyButton>
                        </div>
                        {(!this.state.show)?(
                            ""
                        ):(
                            <div className="col-12 align-self-center" style={{ height: "600px", padding: "0px", marginTop: "30px" }}>
                            <MapContainerPolyline isMarkerShown={true} direction={values.busqueda} lat={this.state.lat} lng={this.state.lng} marca={this.state.marca}/>
                            </div>
                        )}
                        
                        <div className="col-12">
                            <div className="siguiente" >
                                <div className="boton-siguiente">
                                    <div className="mx-auto"><br />
                                    <MyButton onClick={resetForm}>Reset</MyButton>&nbsp;&nbsp;
                                    {values.busqueda != "" ? (
                                        <MyButton onClick={nextStep}>Siguiente</MyButton>
                                    ):(
                                        <MyButton onClick={() => {alert("Debe ingresar una dirección y presionar buscar, antes de continuar")}}>Siguiente</MyButton>
                                    )}
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ThemeProvider>
            </React.Fragment>
        );
    }
}


