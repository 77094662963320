import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class DownloadCategories extends React.Component {
  constructor(props){
      super(props)
  }
    render() {
        //console.log(this.props.centers)
        return (
            <ExcelFile element={<button>Exportar</button>}>
                <ExcelSheet data={this.props.categories} name="Categories">
                    <ExcelColumn label="Id" value="id"/>
                    <ExcelColumn label="Nombre" value="name"/>
                    <ExcelColumn label="Descripción" value="description"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}