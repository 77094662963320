import React, { Component } from 'react';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Select, InputLabel, FilledInput } from '@material-ui/core';

const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
     },
});

//components

export default class FormMaterials extends Component {

    constructor(props) {
        super(props)

        this.state = {
            step: 1,
        }
    }

    render() {
        const classes = makeStyles(theme => ({
            root: {
                display: 'flex',
            },
            formControl: {
                margin: theme.spacing(3),
            },
            group: {
                margin: theme.spacing(1, 0),
            },
            textField: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        }));

        const CssTextField = withStyles({
            root: {

            },
        })(Select);

        const { values, handleChange, prevStep, nextStep, handleAdd,resetForm } = this.props;
        const user = localStorage.getItem("user");
        var totalDetalle = JSON.parse(localStorage.getItem("totalDetalle"));

        if(totalDetalle!=''){
            var detalle = '';
        }else{
            var detalle = totalDetalle;

        }

        return (<div>
                {!user?(
                    <div>
                    <div style={{backgroundColor:"#1BE9B6", width:"450px", height:"100px", margin:"50px auto", paddingTop:"20px"}}>
                    <span>Excelente, en un plazo de 24 a 48 horas un especialista de <br />UberGreen te contáctara para coordinar {(values.tipo==="retiro")?("el retiro."):("la entrega.")}<br />
                    <div className="text-center">
                    <b>{detalle}</b>
                    </div>
                    </span>
                    </div>
                    <div className="container-fluid recicla" >
                        <div className="col-8 text-center ml-auto  mr-auto"><br />
                            <MyButton onClick={resetForm}>
                            Finalizar
                            </MyButton>
                        </div>
                </div></div>
                ):(
                   ""
                )}</div>
        )
    }
}


