import React, { Component } from 'react';

export default class BannerShort extends Component{
    constructor(props){
        super(props)

        this.state = {}
    }

    render(){
        //Imageweather.png
        const { title, image } = this.props;
        return(
            <div className="container-fluid">
                <div className="banner">
                    <img src={image} className="img-fluid" alt="Responsive image"></img>
                    <div className="texto-banner-short" style={{ backgroundColor: "#1BE9B6" }}>                        
                        <a href="#" style={{ paddingLeft:'40px', color: "#000000", fontSize: "22px", fontWeight: "bold", textDecoration: "none" }}>{title}</a>
                    </div>
                </div>
            </div>
        )
    }
}