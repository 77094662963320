import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class DownloadProductos extends React.Component {
  constructor(props){
      super(props)
  }
    render() {
        //console.log(this.props.productos)
        return (
            <ExcelFile element={<button>Exportar</button>}>
                <ExcelSheet data={this.props.productos} name="Productos">
                    <ExcelColumn label="Codigo" value="code"/>
                    <ExcelColumn label="Centro" value="center"/>
                    <ExcelColumn label="Nombre" value="name"/>
                    <ExcelColumn label="Categoría" value="categoryName"/>
                    <ExcelColumn label="Valor" value="profitPerUnit"/>
                    <ExcelColumn label="Unidad de Medida" value="umName"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}