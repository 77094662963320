import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  step: {
    activeStep: {
      color:"secundary"
    }
  }
}));

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiStepLabel: {
      // Name of the rule
      label:{
        color: "#cccccc",
        '&$active': { color: "#1BE9B6" },
        '&$completed': {color: "#80AFAB"}
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        marginTop:'5px',
        marginLeft: '5px',
        marginRight: '5px',
        borderColor: '#eeeeee',
        borderTopWidth: 3,
      },
      active: {'& $line': { 
        borderColor: '#1BE9B6', 
        borderTopWidth: 3,
      },
    },
      completed: { '& $line': { 
        borderColor: '#80AFAB',
        borderTopWidth: 3,
      },
    },
  },
  MuiStepIcon: {
    root: {
      backgroundColor: '#fff',
      zIndex: 1,
      color: '#cccccc',
      width: 40,
      height: 40,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      '&$active': {
          color: '#1BE9B6',
      },
      '&$completed': {
        color: '#80AFAB',
    }
    },
  }
  },
});

function getSteps() {
  return ['Definir retiro o entrega', 'Tipo de material', 'Confirmación de datos'];
}

function getStepContent(stepIndex, values) {
  switch (stepIndex) {
    case 0:
      return <React.Fragment>Define si vamos a retirar a tu domicilio, o tu te<br /> acercas a nuestros puntos de recoleccion.</React.Fragment>
    case 1:
      return <React.Fragment>Ingresa cuantos productos quieras, indicando la<br />unidad de medida y la cantidad.</React.Fragment>
    case 2:
      return <React.Fragment>Confirma tus datos de {values.tipo}.</React.Fragment>
    case 4:
      return <React.Fragment></React.Fragment>
    default:
      return <React.Fragment>Operación realizada con éxito.</React.Fragment>
  }
}

export default function HorizontalLabelPositionBelowStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const { activeSteps } = props;

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleReset() {
    setActiveStep(0);
  }

  const { values } = props;
  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
      <Stepper activeStep={activeSteps} alternativeLabel>
        {steps.map(label => (
          <Step key={label} className={classes.step}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      </ThemeProvider>
      <div>
        {activeSteps === steps.length ? (
          <div>
            <Typography className={classes.instructions}>Ha finalizado el proceso con exito!!!</Typography>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeSteps,values)}</Typography>
            <div>
            { /* <Button
                disabled={activeSteps === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeSteps === steps.length - 1 ? 'Finish' : 'Next'}
              </Button> */ 
            }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}