import React, { Component } from 'react';
import Header from './Header';
import Galery from './Galery';
import Information from './Information';
import ContactForm from './ContactForm';
import Banner from './Banner';
import Footer from './Footer';

export default class Home extends Component{

    constructor(props){
        super(props)
        
        this.state={}
    }

    render(){
        return(
        <React.Fragment>
        <Banner/>    
        <Information/>
        <div id="materiales">
        </div>
        <Galery/>
        <div id="contactanos"></div>
        <ContactForm/>
        <Footer/>
        </React.Fragment>    
        )
    }

}