import React, { Component } from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps"
import firebase from './firebase';
import Geocode from "react-geocode";
import { loadPartialConfig } from "@babel/core";

/************************** */
const google = window.google = window.google ? window.google : {}

const zoneRegions = firebase.firestore().collection('ZoneRegions').doc('CVkO25ekZbBc1isTfM8E');
const centros = firebase.firestore().collection('ReciclerCenters');
const regiones = zoneRegions.get();
var coords = [];
var centers = [];

async function getRegions() {
    return new Promise(async (fulfill, reject) => {
        regiones.then((data) => {
            // console.log(data.data().points);
            const dataSet = data.data().points.map((p) => {
                return {
                    lat: p.latitude,
                    lng: p.longitude
                }
            });
            coords = dataSet;
            // polyline.push(dataSet[0].points);
            //   console.log(coords);
            fulfill(coords);
        }).catch((error) => {
            reject(error);
        });
    })
}

async function getCenters() {
    return new Promise(async (fulfill, reject) => {
        centros.get().then((snapshot) => {
            if (snapshot.empty) {
                console.log('No matching documents.');
                return;
            }

            const centers = snapshot.forEach(doc => {
                //     console.log(doc.id, '=>', doc.data());

            });
            fulfill(centers);
        }).catch((error) => {
            reject(error);
        });
    })
}




/************************** */

const coords2 = [
    { lat: -35.4068057, lng: -71.6443915 },
    { lat: -35.4162683, lng: -71.6043925 },
    { lat: -35.4232444, lng: -71.6484804 },
    { lat: -35.4368573, lng: -71.6214061},
    { lat: -35.4068057, lng: -71.6443915 },


];

/*const paths = [
    new google.maps.LatLng(-35.4279278, -71.66956902),
    new google.maps.LatLng(-35.42147569, -71.66886091),
    new google.maps.LatLng(-35.42182104, -71.66343749),
    new google.maps.LatLng(-35.42880203, -71.65997744),
    new google.maps.LatLng(-35.4279278, -71.66956902),
];
*/

/*var bermudaTriangle = new window.google.maps.Polygon({ paths });
var xbermudaTriangle = new window.google.maps.Polygon({
    paths: [
        new google.maps.LatLng(25.774, -80.190),
        new google.maps.LatLng(18.466, -66.118),
        new google.maps.LatLng(32.321, -64.757)
    ]
});*/


export default class MyMapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coords: [],
            inside:false
        }
    }

    async componentWillMount() {
        const result = await getRegions();
        const result2 = await getCenters();
        this.setState({
            coords: result
        })
        //console.log(result2);
    }

    consultarDireccion = async (direction,bermudaTriangle) => {
        return new Promise(async (fulfill, reject) => {
            try {
                const inside = await Geocode.fromAddress(direction);
                const { lat, lng } = inside.results[0].geometry.location;
                var coordinate = new google.maps.LatLng(lat, lng);
                /*   if (window.google.maps.geometry.poly.containsLocation(coordinate, bermudaTriangle)) {
                     //  console.log('inside the city');
                       return fulfill({state: true});
                   } else {
                     //  console.log('outside the city');
                       return reject(false)
                   }*/
            } catch (error) {
                console.log(error);
            }
        })
    }

    render() {

        const result2 = getCenters();
        //  console.log(result2);

        const direction = this.props.direction;
        const lat = this.props.lat;
        const lng = this.props.lng;
        const marca = this.props.marca;


        if (direction != "") {
            Geocode.setApiKey("AIzaSyCNFNOpf7RT_JbBBSkMfWU5CRpmwsr5lJY");
            Geocode.setLanguage("en");
            Geocode.setRegion("es");
            Geocode.enableDebug();
            const inside = Geocode.fromAddress(direction).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    console.log(lat, lng);
                    localStorage.setItem("latlong",JSON.stringify({lat, lng}));
                    //   var coordinate = new google.maps.LatLng(lat, lng);
                    /*  if (window.google.maps.geometry.poly.containsLocation(coordinate, bermudaTriangle)) {
                          console.log('inside the region');
                      return true;
                      } else {
                          console.log('outside the region');
                          return false;
                      }*/
                    console.log(lat, lng);
                },
                error => {
                    console.error(error);
                }
            );
        }
        /*********************/

        /*     var origin = new google.maps.LatLng(55.930385, -3.118425),
                 destination = "Stockholm, Sweden",

            calcDistance = (fromLat, fromLng, toLat, toLng) =>{
             return google.maps.geometry.spherical.computeDistanceBetween(
               new google.maps.LatLng(fromLat, fromLng), new google.maps.LatLng(toLat, toLng));
          }*/

        /*****************************/

        const reversedCoords = coords.map(ll => {
            return { lat: ll.lat, lng: ll.lng }
        });

        const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");
        const latlng = JSON.parse(localStorage.getItem('latlong'));
        let latlnglat = -35.4266305;
        let latlnglng = -71.6661153;
        if(latlng){
            latlnglat = latlng.lat;
            latlnglng = latlng.lng;
        }


        const MyMapComponents = compose(
            withProps({
                //googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCnG7MQnilXLO9iykjLIQ_DyjQDP6BJ87w",
                googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCNFNOpf7RT_JbBBSkMfWU5CRpmwsr5lJY",
                loadingElement: <div style={{ height: `100%` }} />,
                containerElement: <div style={{ height: `600px` }} />,
                mapElement: <div style={{ height: `100%` }} />,
            }),
            withScriptjs,
            withGoogleMap
        )((props) =>
            <GoogleMap defaultZoom={15} defaultCenter={{ lat: latlnglat, lng: latlnglng }}>
                {props.isMarkerShown && <Marker position={{ lat: latlnglat, lng: latlnglng }} />}
                <Polygon
                    // path={reversedCoords}
                    //key={1}
                    options={{
                        fillColor: "#000",
                        fillOpacity: 0.4,
                        strokeColor: "#000",
                        strokeOpacity: 1,
                        strokeWeight: 1
                    }} />
                {(marca)?(
                    <MarkerWithLabel
                        position={{ lat: latlnglat, lng: latlnglng }}
                        labelAnchor={new google.maps.Point(0, 0)}
                        labelStyle={{ backgroundColor: "#1BE9B6", fontSize: "18px", padding: "16px" }}>
                        <div>Usted esta aquí!</div>
                    </MarkerWithLabel>
                ):("")}


            </GoogleMap>
        )

        return (
            <MyMapComponents />
        )
    }
}