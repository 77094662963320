import React, { Component } from 'react';
import { styled } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import checked from './checked.png'
import uber_tipo_material from './uber-tipomaterial.png'
import uber_retiro from './uber-retiro.png'
import uber_dinero from './uber-dinero.png'
import uber_beneficio from './uber-beneficio.png'




const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
     },
  });

export default class Information extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    style = { backgroundColor: '#ffffff', paddingRight:'40px' , paddingLeft:'40px' };

    render() {
        return (
            <React.Fragment>
                <div className='container-fluid' style={this.style}>
                <div className="row text-center">
                <div className="col-12 col-lg-12" style={{paddingTop: "40px"}}>
                <h3><img src={checked} width="60px" style={{paddingRight:"10px"}}/>Recicla en tres simples pasos desde tu hogar, institución o empresa.</h3>
                </div>    
                </div>
                    <div className="row  how-to">                    
                        <div className="col-12 col-lg-4 how-to-left" style={{paddingBottom:"25px"}}>
                            <h1>¿Cómo funciona <br />uber green?</h1>
                            A través de nuestra plataforma conectamos las necesidades de reciclar de los usuarios generadores 
                            con recicladores autorizados, contribuimos al desarrollo de nuestra ciudad basándonos en tres conceptos: 
                            valor económico, mirada social e impacto ambiental.
                        </div>
                        <div className="col-12 col-lg-8 how-to-right">
                            <div className="row  how-to-content">
                                <div className="col-md-6">
                                    <img src={uber_tipo_material} className="img-fluid" alt="Responsive image" width="70px" width="70px"></img>
                                    <span>Tipo de Material</span>
                                    <p>Ingresa a nuestra sección materiales y podrás conocer todo lo que puedes reciclar y sus condiciones (Incorporar condiciones en cada imagen, limpios, secos, separado por color, etc.).</p>
                                </div>
                                <div className="col-md-6">
                                    <img src={uber_retiro} className="img-fluid" alt="Responsive image"></img>
                                    <span>Retiro o Entrega</span>
                                    <p>De manera simple podrás coordinar  el retiro o entrega de tus residuos desde su origen, ya sea tu hogar, tu condómino, institución o empresa.</p>
                                </div>
                            </div>
                            <div className="row  how-to-content">
                                <div className="col-md-6">
                                    <img src={uber_dinero} className="img-fluid" alt="Responsive image" style={{paddingRight:"5px"}}></img>
                                    <span>Valorizamos tus residuos</span>
                                    <p>El valor de tus residuos es el resultado de la ecuación retiro o entrega, tipo de material y precio por kilo.</p>    
                                </div>
                                <div className="col-md-6">
                                    <img src={uber_beneficio} className="img-fluid" alt="Responsive image"  style={{paddingRight:"5px"}}></img>
                                    <span>Beneficio medio ambiental</span>
                                    <p>Juntos estaremos ayudando a disminuir el uso de los recursos, reducir el envio de basura a vertederos y contribuir a la reducción  de consumo de energía.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid recicla" >
                        <div className="row boton-recicla">
                            <div className="mx-auto">
                                <MyButton>
                                    <Link to={"/recicla"} style={{color:"white", textTransform:"capitalize"}}>Recicla ></Link>
                                    </MyButton>
                            </div>
                        </div>
                </div>
            </React.Fragment >
        )
    }
}