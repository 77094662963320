import React, { Component } from 'react';
export default class ErrorPage extends Component{

    render(){
        return(
            <React.Fragment>
            <div className="container text-center" style={{fontSize:"10vw", color:"#1BE9B6", margin:"200px auto"}}>
                404
                <br />
            <h1>Not Found</h1>
            </div>
            </React.Fragment>
        )
    }
}