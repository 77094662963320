import React, {Component } from 'react';

export default class Profile extends Component{
    constructor(props){
        super(props);

        this.state={
            isAuth: "si"
        }
    }

    componentDidMount(props){
        if(this.props.isAuth==true){
            this.setState({
                isAuth: "si"
            })    
        }else{
            this.setState({
                isAuth: "No"
            })
        }
        
    }
        render(){
            return(
                <h1>isAuth: {this.state.isAuth}</h1>
            )
        }
    
}