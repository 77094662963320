import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Download extends React.Component {
  constructor(props){
      super(props)
  }
    render() {
       // console.log(this.props.orders)
        return (
            <ExcelFile element={<button>Exportar</button>}>
                <ExcelSheet data={this.props.orders} name="Ordenes">
                    <ExcelColumn label="Codigo" value="code"/>
                    <ExcelColumn label="Delivery" value={(col) => col.delivery ? "Retiro" : "Entrega"}/>
                    <ExcelColumn label="Dirección" value="address"/>
                    <ExcelColumn label="Fecha" value="fecha"/>
                    <ExcelColumn label="Contacto" value="userEmail"/>
                    <ExcelColumn label="Nombre" value="userName"/>
                    <ExcelColumn label="Numero de Contacto" value="userPhone"/>
                    <ExcelColumn label="Status" value="status"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}