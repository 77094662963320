import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const columns = [
  { id: 'name', label: 'Nombre', minWidth: 100 },
  { id: 'descripcion', label: 'Descripcion', minWidth: 100 },
  { id: 'delivery', label: 'Delivery', minWidth: 100 },
  //{ id: 'longitude', label: 'Longitud', minWidth: 100 },
];

function createData(name, descripcion, delivery, longitude) {
  return { name, descripcion, delivery, longitude };
}

const rows = [
  createData('India', 'IN', 1324171354, 3287263, 3287263),
  createData('China', 'CN', 1403500365, 9596961, 3287263),
  createData('Italy', 'IT', 60483973, 301340, 3287263),
  createData('United States', 'US', 327167434, 9833520, 3287263),
  createData('Canada', 'CA', 37602103, 9984670, 3287263),
  createData('Australia', 'AU', 25475400, 7692024, 3287263),
  createData('Germany', 'DE', 83019200, 357578, 3287263),
  createData('Ireland', 'IE', 4857000, 70273, 3287263),
  createData('Mexico', 'MX', 126577691, 1972550, 3287263),
  createData('Japan', 'JP', 126317000, 377973, 3287263),
  createData('France', 'FR', 67022000, 640679, 3287263),
  createData('United Kingdom', 'GB', 67545757, 242495, 3287263),
  createData('Russia', 'RU', 146793744, 17098246, 3287263),
  createData('Nigeria', 'NG', 200962417, 923768, 3287263),
  createData('Brazil', 'BR', 210147125, 8515767, 3287263),
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: 'auto',
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [open, setOpen] = React.useState(false);

function handleClickOpen() {
  setOpen(true);
}

function handleClose() {
  setOpen(false);
}

function handleCloseSubmit() {
  handleSubmit();
  setOpen(false);
}

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const { values } = props; 
  const { handleChange, handleSubmit, handleChangeSelect } = props;
  const rows = [];

  values.zonas.map(item =>{
    return rows.push(createData(item.name, item.description, item.deliveryCost, ""));
  });

  return (
    <React.Fragment>
    <div className="row">
      <div className="col col-md-12 text-right" style={{paddingRight:'10px', paddingBottom:'20px'}}>
            <button onClick={handleClickOpen} type="submit" class="btn btn-primary mb-2">Agregar Zona</button>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Agregar Zona</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingrese los datos correspondientes al centro que desea agregar.
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label="Nombre"
            type="text"
            fullWidth
            onChange={handleChange("name")}
          />
          <TextField
            margin="dense"
            id="descripcion"
            label="Descripción"
            type="text"
            fullWidth
            onChange={handleChange("descripcion")}
          />
          <TextField
            margin="dense"
            id="delivery"
            label="Costo de Delivery"
            type="text"
            fullWidth
            onChange={handleChange("delivery")}
          />
          <TextField
            margin="dense"
            id="longitud"
            label="Longitud"
            type="text"
            fullWidth
            onChange={handleChange("longitud")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseSubmit} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
    </React.Fragment>
  );
}