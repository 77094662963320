import React, { Component } from 'react';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { Button, MenuItem, Select, InputLabel, FilledInput } from '@material-ui/core';
import BannerShort from './BannerShort';
import Stp from './Stp';

//components
import Selection from './Selection';

const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
     },
});

//components

export default class FormMaterials extends Component {

    constructor(props) {
        super(props)

        this.state = {
            step: 1,
        }
    }

    render() {
        

        const CssTextField = withStyles({
            root: {

            },
        })(Select);

        return (<div>
                        <BannerShort title="Recicla" image="Imageweather.png"/>    
            {!(localStorage.getItem('userId')) ? (
                <div>
                    <Stp activeSteps={4}/>
                    <div style={{ backgroundColor: "pink", width: "400px", height: "100px", margin: "50px auto", paddingTop: "20px", textAlign:"center" }}>
                        <span>Para poder realizar tu solicitud es necesario <br />ingresar o registrarse en nuestra plataforma.</span>
                    </div>
                    <div className="container-fluid recicla" >
                        <div className="col-8 text-center ml-auto  mr-auto"><br />
                            <MyButton  style={{textTransform: "capitalize"}}>
                                <Link to={'/login'} style={{ color: "#ffffff" }}>
                                    INGRESA
                            </Link>
                            </MyButton>
                        </div>
                    </div>
                </div>
            ) : ("")}</div>
        )
    }
}