import React, { Component } from 'react';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button, MenuItem, Select, InputLabel, FilledInput } from '@material-ui/core';
import BotonPlus from './BotonPlus';
import Radio from '@material-ui/core/Radio';
import SnackbarContent from '@material-ui/core/SnackbarContent';

//components

const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #28B463 30%, #28B463 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(27, 233, 182, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(45deg, #20874c 30%, #20874c 90%)',
     },
});

const GreenRadio = withStyles({
    root: {
        color: "#333333",
        '&$checked': {
            color: "#1BE9B6",
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

export default class FormMaterials extends Component {

    constructor(props) {
        super(props)

        this.state = {
            step: 1,
        }

        this.handleFormMaterials =  this.handleFormMaterials.bind(this);
    }

    handleFormMaterials = ()=>{
        if(this.props.values.detalleRecicle.length < 1  ){
            alert("Debe ingresar al menos 1 material a reciclar!");
            return;
        }else{
            console.log(this.props.values.detalleRecicle.length);
            this.props.nextStep();
        }
        //alert(this.props.values.material);
        //alert(this.props.values.cantidad);
    }

    updateInputValue(evt) {
        this.setState({
            inputValue: evt.target.value
        });
    }
    render() {
        const classes = makeStyles(theme => ({
            root: {
                display: 'flex',
            },
            formControl: {
                margin: theme.spacing(3),
            },
            group: {
                margin: theme.spacing(1, 0),
            },
            textField: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        }));

        const CssTextField = withStyles({
            root: {

            },
        })(Select);

        const { selectedCenter, values, handleChange, handleChangeMaterial, handleChangeCategories, prevStep, nextStep, handleAdd, oStorage ,resetForm} = this.props;
        const cant = [];

        for(let i = 1; i <= 10; i++){
            cant.push(i);
        }



        var ganancia = 0;
        var total = 0;
        var descuento = 3000;
        var aprox = 0;
        values.detalleRecicle.map(item => {
            ganancia = parseInt(item.cantidad * item.valor);
            total += ganancia;


        });

        if(values.detalleRecicle.length < 1){
            descuento = 0;
        }
        
        (values.tipo == "retiro") ? aprox = total - descuento : aprox = total;

        var formatNumber = {
            separador: ".", // separador para los miles
            sepDecimal: ',', // separador para los decimales
            formatear: function (num) {
                num += '';
                var splitStr = num.split('.');
                var splitLeft = splitStr[0];
                var splitRight = splitStr.length > 1 ? this.sepDecimal + splitStr[1] : '';
                var regx = /(\d+)(\d{3})/;
                while (regx.test(splitLeft)) {
                    splitLeft = splitLeft.replace(regx, '$1' + this.separador + '$2');
                }
                return this.simbol + splitLeft + splitRight;
            },
            new: function (num, simbol) {
                this.simbol = simbol || '';
                return this.formatear(num);
            }
        }

        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="container text-center">
                    <div className="row" style={{ backgroundColor: "#fff" }}>
                        <div className="col-4 ml-auto text-left">
                            Retiro
                            <GreenRadio
                                checked={values.tipo === 'retiro'}
                                onChange={handleChange('tipo')}
                                value="retiro"
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'retiro' }}
                            />

                        </div>
                        <div className="col-4 mr-auto text-right">
                            Entrega
                            <GreenRadio
                                checked={values.tipo === 'entrega'}
                                onChange={handleChange('tipo')}
                                value="entrega"
                                name="radio-button-demo"
                                inputProps={{ 'aria-label': 'entrega' }}
                            />

                        </div>
                        </div>
                        <div className="row">
                            <div className="col-8 mx-auto ml-auto mr-auto">
                                <TextField select
                                    id="standard-name"
                                    label="Categoria"
                                    name="categoria"
                                    className={classes.textField}
                                    value={values.categoria}
                                    onChange={handleChangeCategories('categoria')}
                                    fullWidth
                                    margin="normal">
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    {values.categories.map((item,i)=>(
                                        <MenuItem title={item.umName} key={i} id={item.profitPerUnit} value={item.name}>{item.name}</MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="col-8 mx-auto ml-auto mr-auto">
                                <TextField select
                                    id="standard-name"
                                    label="Productos"
                                    name="material"
                                    className={classes.textField}
                                    value={values.material}
                                    onChange={handleChangeMaterial('material')}
                                    fullWidth
                                    margin="normal">
                                    <MenuItem value=""><em>Ninguno</em></MenuItem>
                                    {values.productos.map((item,i)=>(
                                        <MenuItem title={item.umName} key={i} id={item.profitPerUnit} value={item.name}>{item.name} ({item.umName})</MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div className="col-8 mx-auto ml-auto mr-auto">
                                <div className="row">
                            <div className="col-6">
                                <TextField
                                    type="number"
                                    id="standard-name"
                                    label="Cantidad"
                                    name="cantidad"
                                    className={classes.textField}
                                    value={values.cantidad}
                                    onChange={handleChange('cantidad')}
                                    fullWidth
                                    margin="normal">
                                </TextField>
                                    </div>
                            <div className="col-6">
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 mx-auto ml-auto mr-auto text-left">
                                <BotonPlus detalleRecicle={values.detalleRecicle} userOrder={oStorage} handleAdd={handleAdd} values={values} selectedCenter={selectedCenter} handleChange={handleChange}/>
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid recicla" >
                    <div className="row boton-recicla">
                        <div className="col-8 text-right ml-auto  mr-auto"><br />
                        <div className="row">
                            <div className="col-6 text-left">
                         {/*       <span><h4><b>Ganancia Aproximada: {formatNumber.new(aprox, "$")}</b></h4></span>*/}
                            </div>
                            <div className="col-12">
                                <MyButton onClick={resetForm}>Reset</MyButton>&nbsp;&nbsp;

                                <MyButton onClick={prevStep}>Atras</MyButton>&nbsp;&nbsp;
                            <MyButton onClick={this.handleFormMaterials}>Siguiente</MyButton>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            
        );
    }
}